import React, { useRef, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { faEnvelope, faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Switch } from '@headlessui/react'
import { Form, Link, useActionData, useLoaderData, useNavigation, useSubmit } from 'react-router'
import useConfirmModal from '@hooks/useConfirmModal'
import { Button } from '@designsystem'
import SoraIcon, { soraCheck, soraClose, soraCloudDownloadOutline, soraCloudUploadOutline } from '@components/sora-icon'

export default function EnrolmentsRoute() {
  const inputFileRef = useRef(null)

  const submit = useSubmit()
  const navigation = useNavigation()

  const loaderData = useLoaderData()
  const familyEnrolments = loaderData.enrolments

  const actionData = useActionData()
  const csvErrors = actionData?.csvErrors
  const parsedCSVData = actionData?.parsedCSVData
  const rawCSVData = actionData?.rawCSVData

  const isUploadEnrolmentCSV =
    navigation.state === 'submitting' &&
    navigation.formData?.get('formType') === 'uploadEnrolmentCSV'

  useEffect(() => {
    if (!isUploadEnrolmentCSV) {
      inputFileRef.current?.form.reset()
    }
  }, [isUploadEnrolmentCSV])

  const showConfirmBtn = !!((!csvErrors || csvErrors.length === 0) && parsedCSVData)

  return (
    <div className="flex flex-col space-y-8">
      <div className="flex w-full items-center">
        <div className="flex justify-between border rounded-xl p-5">
          <div className="hidden xl:flex flex-col whitespace-nowrap mr-3">
            <span>Have a new list of enrolled families?</span>
            <span>Add the CSV file here!</span>
          </div>
          <div className="my-auto flex space-x-2 whitespace-nowrap">
            <Form method="POST" encType='multipart/form-data'>
              <input type="hidden" name="formType" value="uploadEnrolmentCSV" />
              <input
                className="hidden"
                ref={inputFileRef}
                onChange={e => submit(e.target.form)}
                type="file"
                name="enrolled_families_csv"
                multiple={false}
                accept=".csv"
              />
            </Form>
            <button
              className="flex px-4 py-1 rounded-lg bg-gray-10"
              onClick={e => {
                e.preventDefault()
                inputFileRef.current.click()
              }}
            >
              <SoraIcon icon={soraCloudUploadOutline} className="mr-3" />
              UPLOAD CSV
            </button>
            {showConfirmBtn && (
              <Form method="POST" className="block">
                <input type="hidden" name="formType" value="confirmCSVUpload" />
                <input type="hidden" name="parsedCSVData" value={JSON.stringify(parsedCSVData)} />
                <button
                  type="submit"
                  className="flex px-4 py-1 rounded-lg bg-blue-60 text-white"
                >
                  CONFIRM UPLOAD
                </button>
              </Form>
            )}
          </div>
        </div>
        <div className="ml-auto">
          <button
            className="flex px-4 py-1 rounded-lg bg-gray-10 text-xs items-center space-x-3"
            onClick={downloadCSVTemplate}
          >
            <SoraIcon icon={soraCloudDownloadOutline} />
            <span className="hidden lg:flex">DOWNLOAD TEMPLATE</span>
          </button>
        </div>
      </div>
      <CSVErrorsList csvErrors={csvErrors} />
      <CSVInputTable rawCSVData={rawCSVData} />
      <FamilyEnrolmentsTable enrolments={familyEnrolments} />
    </div>
  )
}

const studentFields = [
  { key: 'student_first_name', label: 'First Name' },
  { key: 'student_preferred_name', label: 'Preferred Name' },
  { key: 'student_middle_name', label: 'Middle Name' },
  { key: 'student_last_name', label: 'Last Name' },
  { key: 'student_pronouns', label: 'Pronouns' },
  { key: 'student_gender', label: 'Gender' },
  { key: 'student_birth_date', label: 'Birth Date' },
  { key: 'student_school_stage_expected_graduation_year', label: 'Expected School Stage Graduation Year' },
  { key: 'student_school_stage', label: 'School Stage (ms or hs)' },
  { key: 'student_school_stage_start_year', label: 'School Stage Start Year (Aug 1st)' },
  { key: 'student_sora_planned_start_date', label: 'Sora Planned Start Date' },
  { key: 'student_hubspot_id', label: 'Hubspot ID' },
  { key: 'student_photo_url', label: 'Student Photo URL' },
]

const guardianFields = [
  [
    { key: 'guardian_1_first_name', label: 'First Name' },
    { key: 'guardian_1_last_name', label: 'Last Name' },
    { key: 'guardian_1_email', label: 'Email' },
    { key: 'guardian_1_phone', label: 'Phone' },
    { key: 'guardian_1_address', label: 'Address' },
    { key: 'guardian_1_state', label: 'State' },
    { key: 'guardian_1_is_primary', label: 'Is Primary' },
    { key: 'guardian_1_relationship', label: 'Relationship' },
    { key: 'guardian_1_hubspot_id', label: 'Hubspot ID' },
  ],
  [
    { key: 'guardian_2_first_name', label: 'First Name' },
    { key: 'guardian_2_last_name', label: 'Last Name' },
    { key: 'guardian_2_email', label: 'Email' },
    { key: 'guardian_2_phone', label: 'Phone' },
    { key: 'guardian_2_address', label: 'Address' },
    { key: 'guardian_2_state', label: 'State' },
    { key: 'guardian_2_is_primary', label: 'Is Primary' },
    { key: 'guardian_2_relationship', label: 'Relationship' },
    { key: 'guardian_2_hubspot_id', label: 'Hubspot ID' },
  ],
]

const emergencyContactFields = [
  { key: 'emergency_contact_first_name', label: 'First Name' },
  { key: 'emergency_contact_last_name', label: 'Last Name' },
  { key: 'emergency_contact_email', label: 'Email' },
  { key: 'emergency_contact_phone', label: 'Phone' },
  { key: 'emergency_contact_relationship', label: 'Relationship' },
]

const csvFields = [...studentFields, ...guardianFields[0], ...guardianFields[1], ...emergencyContactFields]

const downloadCSVTemplate = () => {
  const element = document.createElement('a')
  element.href = window.URL.createObjectURL(
    new Blob([csvFields.map((f) => f.key).join(',')], { type: 'text/csv' })
  )
  element.setAttribute('download', 'family-enrolment.csv')
  element.style.display = 'none'
  document.body.appendChild(element)
  element.click()
  document.body.removeChild(element)
}

const CSVInputTable = ({ rawCSVData }) => {
  if (!rawCSVData) return null
  return (
    <div className="overflow-x-auto">
      <h2 className="mb-1 text-xl font-bold">Uploaded CSV:</h2>
      <table className="table-auto">
        <thead className="border">
          <tr>
            <td className="border-b-1" />
            <td colSpan={studentFields.length} className="font-bold border text-center">
              Student
            </td>
            <td colSpan={guardianFields[0].length} className="font-bold border text-center">
              Guardian 1
            </td>
            <td colSpan={guardianFields[1].length} className="font-bold border text-center">
              Guardian 2
            </td>
            <td colSpan={emergencyContactFields.length} className="font-bold border text-center">
              Emergency Contact
            </td>
          </tr>
          <tr className="text-xs bg-gray-5">
            <th />
            {csvFields.map(({ label }, i) => (
              <th key={i} className="px-2 py-1 border">
                {label}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="text-xs text-center">
          {rawCSVData.map((row, i) => (
            <tr key={i} className="bg-gray-2 whitespace-nowrap">
              <td className="px-3 py-1 border-r-1">{i + 1}</td>
              {csvFields.map(({ key }, i) => (
                <td key={i} className="px-3 py-1 border">
                  {row?.[key] || ''}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}
CSVInputTable.propTypes = {
  rawCSVData: PropTypes.array,
}

const CSVErrorsList = ({ csvErrors }) => {
  if (!csvErrors) return null
  return (
    <div>
      <span className="text-xl font-bold">CSV Errors:</span>
      <ul>
        {csvErrors.map((err, i) => (
          <li key={i}>{`Row: ${err.row + 1}, ${err.message}`}</li>
        ))}
      </ul>
    </div>
  )
}
CSVErrorsList.propTypes = {
  csvErrors: PropTypes.array,
}

const EmailDispatchBtn = ({
  student_id = '',
  isDisabled = false,
  emailName,
  emailLabel,
}) => {
  const navigation = useNavigation()
  const isSending = navigation.state === 'submitting'
    && (navigation.formData?.get('studentId') || '') == student_id
    && navigation.formData?.get('emailType') == emailName
  const confirm = useConfirmModal()
  const submit = useSubmit()
  return (
    <Form method="POST" onSubmit={async (e) => {
      e.preventDefault()
      const form = e.currentTarget
      if (await confirm(`Confirm Welcome E-mail dispatch?`)) {
        submit(form)
      }
    }}>
      <input type="hidden" name="formType" value="sendEmail" />
      <input type="hidden" name="emailType" value={emailName} />
      <input type="hidden" name="studentId" value={student_id} />
      <Button
        size="sm"
        as="button"
        type="submit"
        variant="outlined"
        disabled={isDisabled}
        loading={isSending}
        startIcon={faEnvelope}
      >
        {emailLabel}
      </Button>
    </Form>
  )
}
EmailDispatchBtn.propTypes = {
  student_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isDisabled: PropTypes.bool,
  emailName: PropTypes.string,
  emailLabel: PropTypes.string,
  reloadTable: PropTypes.func,
}

const FamilyEnrolmentsTable = ({
  enrolments
}) => {
  const [showOnlyOnGoingEnrolments, setShowOnlyOnGoingEnrolments] = useState(true)
  return (
    <div className="w-full overflow-x-auto">
      <div className="flex flex-col mb-10 items-start">
        <span className="text-xl font-bold">Bulk e-mail dispatch:</span>
        <div className="text-xxs mb-2">
          Send the respective email to all who haven't received it yet.
        </div>
        <div className="flex space-x-3">
          <EmailDispatchBtn
            isDisabled
            emailName="welcome"
            emailLabel="Welcome"
          />
        </div>
      </div>
      <div className="flex items-center space-x-3 mb-1">
        <Switch
          checked={showOnlyOnGoingEnrolments}
          onChange={setShowOnlyOnGoingEnrolments}
          className={`${showOnlyOnGoingEnrolments ? 'bg-gray-10' : 'bg-blue-50'} 
            relative inline-flex items-center h-4 rounded-full w-9
          `}
        >
          <span className={`${showOnlyOnGoingEnrolments ? 'translate-x-1' : 'translate-x-6'} 
            inline-block w-2 h-2 transform bg-white rounded-full
          `} />
        </Switch>
        <div className="text-xl font-bold">
          {showOnlyOnGoingEnrolments ? 'On-going enrolments' : 'All enrolments'}
        </div>
      </div>
      {enrolments.length < 1 ? (
        <span>No families are currently being enrolled.</span>
      ) : (
        <div className="pb-2">
          <table className="border rounded-full w-full">
            <thead className="text-center text-sm">
              <tr>
                <td className="border-b-1" />
                <td className="font-bold border px-3">Student</td>
                <td className="font-bold border px-3">Created SH Email</td>
                <td className="font-bold border px-3">Received Welcome Email</td>
                <td className="font-bold border px-3">Send Emails</td>
                <td className="font-bold border px-3">Action</td>
              </tr>
            </thead>
            <tbody className="text-center text-xs">
              {enrolments
                .filter(
                  (e) =>
                    !showOnlyOnGoingEnrolments || !e.is_completed
                ) // Remove lines where all values are "done"
                .map((row, i) => (
                  <tr
                    key={row.id}
                    className="bg-gray-2 whitespace-nowrap hover:bg-gray-10"
                  >
                    <td className="px-3 py-2 border-r-1">{i + 1}</td>
                    <td className="py-2 border-r-1">
                      <Link
                        className="underline font-bold"
                        to={`/students/${row.student.id}`}
                      >
                        {row.student.name}
                      </Link>
                    </td>
                    <td className="py-2 border-r-1 mx-auto">
                      <SoraIcon icon={row.created_sh_email ? soraCheck : soraClose} className="mx-auto" />
                    </td>
                    <td className="py-2 border-r-1">
                      <SoraIcon icon={row.received_welcome_email ? soraCheck : soraClose} className="mx-auto" />
                    </td>
                    <td className="py-2 px-3 border-r-1">
                      <div className="flex justify-evenly space-x-2">
                        <EmailDispatchBtn
                          isDisabled
                          student_id={row.student.id}
                          emailName="welcome"
                          emailLabel="Welcome"
                        />
                      </div>
                    </td>
                    <td className="flex justify-evenly py-2 px-3 space-x-2">
                      <Form method="POST">
                        <input type="hidden" name="formType" value="completeEnrolment" />
                        <input type="hidden" name="enrolmentId" value={row.id} />
                        <input type="hidden" name="is_completed" value={!row.is_completed + ''} />
                        <Button
                          type="submit"
                          size="sm"
                          variant="outlined">
                          {row.is_completed ? 'Unmark' : 'Mark'} as done
                        </Button>
                      </Form>
                      <Form method="POST">
                        <input type="hidden" name="formType" value="deleteEnrolment" />
                        <input type="hidden" name="enrolmentId" value={row.id} />
                        <Button
                          type="submit"
                          size="sm"
                          variant="outlined">
                          Delete
                        </Button>
                      </Form>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  )
}
FamilyEnrolmentsTable.propTypes = {
  enrolments: PropTypes.array,
}

const LastSyncLabel = ({ lastSync }) => (
  <div className="text-xxs ml-auto text-gray-70">
    {lastSync ? (
      <div className="flex items-center space-x-1 text-xxs">
        {lastSync.loading ? (
          <FontAwesomeIcon
            className="mx-auto text-blue-60 my-1"
            size="1x"
            icon={faSpinner}
            spin
          />
        ) : lastSync.success ? (
          <SoraIcon icon={soraCheck} className="text-sm text-green-30" />
        ) : (
          <SoraIcon icon={soraClose} className="text-sm text-danger-30" />
        )}
        <span>Last sync: {lastSync.date}</span>
      </div>
    ) : (
      'Loading...'
    )}
  </div>
)
LastSyncLabel.propTypes = {
  lastSync: PropTypes.object,
}
