import React from 'react'
import { Outlet, useNavigation } from 'react-router'
import { Transition } from '@headlessui/react'
import { Spinner } from '@design-system'

interface LoadingOutletProps {
  shouldLoad?: boolean
  forcedCollapse?: boolean
}

export function MainOutlet({ shouldLoad = true, forcedCollapse = false }: LoadingOutletProps) {
  const navigation = useNavigation()
  const showSpinner = navigation.state === 'loading' && shouldLoad
  return (
    <>
      <Transition
        show={!showSpinner}
        enter="transition-opacity duration-600"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-600"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
        className={`px-4 sm:px-6 my-4 sm:my-8 flex flex-col grow max-w-full ${forcedCollapse ? '' : 'sm:max-w-[calc(100%-var(--aside-width))]'}`}
      >
        <Outlet />
      </Transition>
      <Transition
        show={showSpinner}
        enter="transition-opacity duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-300"
        leaveFrom="opacity-100"
        leaveTo="opacity-0 hidden"
        className="p-4 sm:px-6 sm:py-8 grow flex flex-col justify-center items-center"
      >
        <Spinner />
      </Transition>
    </>
  )
}
