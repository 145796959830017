import { PillUnitStudentProgram } from '@blocks/pill-unit-student-program'
import SlateTextarea, { BlockButton, LinkButton, MarkButton, RenderedSlate } from '@components/forms/slate-textarea'
import { Avatar, Button, DropdownMenu, Icon, Pill, Typography, useFetcher } from '@design-system'
import React, { useState } from 'react'
import { useNavigate } from 'react-router'
import ReactTimeAgo from 'react-time-ago'
import { Attachment, CommentData, FeedMessage } from './types'

interface MessagesFeedRowProps {
  feedMessage: FeedMessage
  masteryLevels: Record<string, string>
}

export function MessagesFeedRow({ feedMessage, masteryLevels }: MessagesFeedRowProps) {
  const [isEditing, setEditing] = useState(false)
  const navigate = useNavigate()

  return (
    <li
      className={`
          flex gap-2 items-end group
          ${feedMessage.isSelf ? 'justify-end' : 'justify-start'}`}
    >
      {!feedMessage.isSelf && (
        <Avatar size="sm">
          <Avatar.Image src={feedMessage.authorImage} />
          <Avatar.Fallback>{feedMessage.authorName?.charAt(0)}</Avatar.Fallback>
        </Avatar>
      )}

      {feedMessage.isSelf && (
        <CommentDropdownMenu
          visible={['comment', 'assessed_ability', 'assessed_unit'].includes(feedMessage.action) && feedMessage.isSelf}
          deleteVisible={!['assessed_ability', 'assessed_unit'].includes(feedMessage.action)}
          commentId={`${(feedMessage.data as CommentData)?.commentId}`}
          editAction={() => {
            if (['assessed_ability', 'assessed_unit'].includes(feedMessage.action)) {
              navigate('./feedback')
            } else {
              setEditing(!isEditing)
            }
          }}
        />
      )}

      <TextBox feedMessage={feedMessage} isEditing={isEditing} setEditing={setEditing} masteryLevels={masteryLevels} />
      <div className="justify-self-end">
        {feedMessage.isSelf && (
          <Avatar size="sm">
            <Avatar.Image src={feedMessage.authorImage} />
            <Avatar.Fallback>{feedMessage.authorName?.charAt(0)}</Avatar.Fallback>
          </Avatar>
        )}
      </div>
    </li>
  )
}

function TextBox({ feedMessage, isEditing, setEditing, masteryLevels }) {
  switch (feedMessage.action) {
    case 'task_summary':
      return <CommentMessage feedMessage={feedMessage} isEditing={isEditing} setEditing={setEditing} />
    case 'guidebook':
      return <CommentMessage feedMessage={feedMessage} isEditing={isEditing} setEditing={setEditing} />
    case 'assessed_unit':
      return <PerkFeedbackMessage title={'Units Feedback'} feedMessage={feedMessage} />
    case 'assessed_ability':
      return <PerkFeedbackMessage feedMessage={feedMessage} title={'Abilities Feedback'} />
    case 'final_task_assessed_unit':
      return <FinalPerkFeedbackMessage feedMessage={feedMessage} title={'Units Feedback'} masteryLevels={masteryLevels} />
    case 'final_task_assessed_ability':
      return <FinalPerkFeedbackMessage feedMessage={feedMessage} title={'Abilities Feedback'} masteryLevels={masteryLevels} />
    case 'flagged':
      return <ReviewedMessage feedMessage={feedMessage} />
    case 'reviewed':
      return <ReviewedMessage feedMessage={feedMessage} />
    case 'comment':
      return <CommentMessage feedMessage={feedMessage} isEditing={isEditing} setEditing={setEditing} />
    default:
      return <CommentMessage feedMessage={feedMessage} isEditing={isEditing} setEditing={setEditing} />
  }
}

function ReviewedMessage({ feedMessage }: { feedMessage: FeedMessage }) {
  const fetcher = useFetcher()
  return (
    <div className="px-4 py-3 bg-blue-100 rounded-lg">
      <div className="flex justify-end">
        <MessageDateLabel date={feedMessage.createdAt} />
      </div>
      <RenderedSlate value={feedMessage.body} />
      <fetcher.Form method="POST" className="py-2">
        <Button variant="outlined" size="sm" name="_action" value="undo_assessment" loading={fetcher.state !== 'idle'}>
          <Icon name="undo" size="sm" />
          Undo
        </Button>
      </fetcher.Form>
    </div>
  )
}

interface GoogleDriveFileProps {
  file: Attachment,
}

function GoogleDriveFile({ file }: GoogleDriveFileProps) {
  const title = <Typography variant='body' weight='bold'>
    {file.title}
  </Typography>

  return <a href={file.url} target="_blank" className='cursor-pointer'>
    <div className='p-2 rounded-lg border border-gray-50 flex bg-white'>
      <div className='p-4 rounded-lg bg-gray-10 flex-none'>
        <div className='size-6'>
          <img src="/assets/google-document.svg" className='w-6 h-6' alt="Document" />
        </div>
      </div>
      <div className='flex-1 content-center ps-4'>
        {title}
        <Typography variant='footnote'>
          Google Docs
        </Typography>
      </div>
    </div>
  </a>
}

function CommentMessage({
  feedMessage,
  isEditing,
  setEditing,
}: {
  feedMessage: FeedMessage
  isEditing: boolean
  setEditing: Function
}) {
  const fetcher = useFetcher()
  return (
    <div
      className={`px-4 py-3 min-w-60 ${feedMessage.isSelf ? 'bg-neutral-900 bg-opacity-5' : 'bg-blue-100'
        } rounded-lg  max-w-screen-md`}
    >
      <div className="flex justify-between">
        <Typography variant="footnote" weight="bold" className="pb-2">
          {feedMessage.authorName}
        </Typography>
        {fetcher.state === 'submitting' && <Icon name="loader-2" size="xs" className="animate-spin" />}
        <MessageDateLabel date={feedMessage.createdAt} />
      </div>
      {isEditing && (
        <fetcher.Form
          method="POST"
          id="editCommentForm"
          onSubmit={(_) => {
            setEditing(false)
          }}
        >
          <SlateTextarea
            // @ts-expect-error slate component is not typed so ts will yell at us
            value={feedMessage.body}
            className="min-h-32 max-h-64 min-w-[37.5rem]"
            name={`editedBody`}
            customTopBar={
              <>
                <div className="flex items-center border-b-[1px] border-neutral-300  sticky top-0 bg-white z-5 h-9 py-1">
                  <MarkButton
                    disabled={false}
                    format="bold"
                    icon={<Icon className="leading-['inherit']" name="text-bold" size="md" />}
                  />
                  <MarkButton
                    disabled={false}
                    format="italic"
                    icon={<Icon className="leading-['inherit']" name="text-italic" size="md" />}
                  />
                  <BlockButton
                    disabled={false}
                    format="numbered-list"
                    icon={<Icon className="leading-['inherit']" name="list-ordered" size="md" />}
                  />
                  <BlockButton
                    disabled={false}
                    format="bulleted-list"
                    icon={<Icon className="leading-['inherit']" name="list-unordered" size="md" />}
                  />
                  <LinkButton disabled={false} />
                  <MarkButton
                    disabled={false}
                    format="strikethrough"
                    icon={<Icon className="leading-['inherit']" name="text-strikethrough" size="md" />}
                  />
                  <MarkButton
                    disabled={false}
                    format="underline"
                    icon={<Icon className="leading-['inherit']" name="text-underline" size="md" />}
                  />
                </div>
              </>
            }
            customBottomBar={
              <div className="flex items-center sticky bottom-0 bg-white z-5 h-9 p-2 pb-6">
                <div className="flex grow items-end justify-end">
                  <input type="hidden" name="commentId" value={(feedMessage.data as CommentData)?.commentId} />
                  <Button size="xs" type="submit" name="_action" value="edit_comment">
                    <Icon name="send" size="xs" />
                  </Button>
                </div>
              </div>
            }
          />
        </fetcher.Form>
      )}
      {!isEditing && <RenderedSlate value={feedMessage.body} />}
      {feedMessage.data?.comment && <RenderedSlate value={feedMessage.data?.comment} />}
      {!isEditing && feedMessage.attachment && <GoogleDriveFile file={feedMessage.attachment} />}
    </div>
  )
}

function FinalPerkFeedbackMessage({ title, feedMessage, masteryLevels }: { title: string; feedMessage: FeedMessage, masteryLevels: Record<string, string> }) {

  return (
    <div
      className={`px-4 py-3 ${feedMessage.isSelf ? 'bg-neutral-900 bg-opacity-5' : 'bg-blue-100'} rounded-lg break-all`}
    >
      <div className="flex justify-between">
        <Typography variant="footnote" weight="bold" className="pb-2">
          {feedMessage.authorName}
        </Typography>
        <MessageDateLabel date={feedMessage.createdAt} />
      </div>

      <Typography variant="body" weight="bold" className="py-1">
        {title}
      </Typography>
      {feedMessage.data &&
        feedMessage.data.map((el, index) => {
          return (
            <div key={index} className="flex flex-col pt-1 w-96">
              <div className="flex flex-row items-center rounded-t p-2 bg-white">
                <div className="grow shrink basis-0 flex-col justify-start items-start inline-flex">
                  <Typography variant="callout" weight="bold">
                    {el.title}
                  </Typography>
                  <div className="flex gap-2 items-center flex-wrap">
                    <Typography variant="callout">Current Mastery: {el.mastery}</Typography>
                    {el.isExempt && <PillUnitStudentProgram variant="exempt" />}
                    {el.isOffProgram && <PillUnitStudentProgram variant="off-program" />}
                  </div>
                </div>
              </div>

              <div className="flex justify-between items-center py-2 border-t border-neutral-900/opacity-10 px-2 pt-2 bg-white ">
                <Typography variant="callout" weight="bold">
                  Student reflection
                </Typography>
                <Pill rounded>
                  <Pill.Value>{masteryLevels[el.selfAssessedLevel?.toFixed(1)] ?? ` - `}</Pill.Value>
                </Pill>
              </div>

              <div className="flex justify-between items-center py-2 border-t rounded-b border-neutral-900/opacity-10 px-2 pt-2 bg-white ">
                <Typography variant="callout" weight="bold">
                  Final Assessment
                </Typography>
                <Pill rounded color={(el.assessedLevel ?? 0) >= 1 ? 'blue-60' : undefined}>
                  <Pill.Value>{masteryLevels[el.assessedLevel?.toFixed(1)] ?? ` - `}</Pill.Value>
                </Pill>
              </div>
            </div>
          )
        })}
    </div>
  )
}

function PerkFeedbackMessage({ title, feedMessage }: { title: string; feedMessage: FeedMessage }) {
  return (
    <div
      className={`px-4 py-3 ${feedMessage.isSelf ? 'bg-neutral-900 bg-opacity-5' : 'bg-blue-100'
        } rounded-lg break-all w-[35rem]`}
    >
      <div className="flex justify-between">
        <Typography variant="footnote" weight="bold" className="pb-2">
          {feedMessage.authorName}
        </Typography>
        <MessageDateLabel date={feedMessage.createdAt} />
      </div>

      <Typography variant="body" weight="bold" className="py-1">
        {title}
      </Typography>

      {feedMessage.data &&
        feedMessage.data.map((el) => {
          return (
            // TODO: Get unique key from backend. Probably Assessment key ( or creation date, whatever)
            <div className="flex flex-col pt-1 max-w-[47rem]">
              <div className="flex flex-row items-center p-2 bg-white gap-2">
                <div className="grow shrink basis-0 flex-col justify-start items-start inline-flex">
                  <Typography variant="callout" weight="bold">
                    {el.title}
                  </Typography>
                  <Typography variant="callout">Current Mastery: {el.mastery}</Typography>
                </div>
                {FlagStatusEmoji({ flag: el.flag })}
              </div>

              <div className="py-2 border-t border-neutral-900/opacity-10 px-2 pt-2 bg-white ">
                {el?.comment && (
                  <RenderedSlate className="slate-textearea-description break-normal" value={el?.comment} />
                )}
              </div>
            </div>
          )
        })}
    </div>
  )
}

function MessageDateLabel({ date }: { date: number | string | Date }) {
  return (
    <Typography variant="footnote" className="text-neutral-500">
      <ReactTimeAgo date={new Date(date)} locale="en-US" />
    </Typography>
  )
}

function FlagStatusEmoji({ flag }: { flag: 'ontrack' | 'needs_improvement' }) {
  switch (flag) {
    case 'ontrack':
      return <Pill color="success-30" rounded>
        <Pill.Value>
          On track for credit
        </Pill.Value>
      </Pill>
    case 'needs_improvement':
      return <Pill color="yellow-10" rounded>
        <Pill.Value>
          Not on track for credit
        </Pill.Value>
      </Pill>
    default:
      return <Pill color="gray-10" rounded>
        <Pill.Value>
          Not yet covered
        </Pill.Value>
      </Pill>
  }
}

function CommentDropdownMenu({
  visible,
  commentId,
  editAction,
  deleteVisible,
}: {
  visible: boolean
  commentId: string
  editAction: () => void
  deleteVisible: boolean
}) {
  const [open, setOpen] = useState(false)
  const fetcher = useFetcher()
  return (
    <DropdownMenu
      onOpenChange={(change) => {
        setOpen(change)
      }}
    >
      <DropdownMenu.Trigger asChild>
        <Button
          className={`${visible ? (open ? 'visible' : 'invisible group-hover:visible') : 'invisible'} self-center`}
          variant="outlined"
          color="soft"
          size="sm"
        >
          <Icon name="more-horiz-filled" size="sm" />
        </Button>
      </DropdownMenu.Trigger>

      <DropdownMenu.Content>
        <DropdownMenu.Item className="hover:bg-neutral-100" onClick={editAction}>
          <button className="flex items-center gap-2">
            <Icon name="edit-3" />
            <Typography variant="callout">Edit Message</Typography>
          </button>
        </DropdownMenu.Item>
        {deleteVisible && (
          <fetcher.Form method="POST" id="deleteCommentForm">
            <input type="hidden" name="commentId" value={commentId} />
            <DropdownMenu.Item className="text-red-600 hover:bg-neutral-100">
              <button
                className="flex items-center gap-2"
                type="submit"
                name="_action"
                value="delete_comment"
                form="deleteCommentForm"
              >
                <Icon name="trash-2" color="danger" />
                <Typography variant="callout" color="danger">
                  Delete Message
                </Typography>
              </button>
            </DropdownMenu.Item>
          </fetcher.Form>
        )}
      </DropdownMenu.Content>
    </DropdownMenu>
  )
}
