import React from 'react'
import { Tabs } from '@design-system'
import { Outlet } from 'react-router'

const Element = () => {
  const [showHeader, setShowHeader] = React.useState(true)

  return (
    <div className='flex flex-col h-full relative'>
      {
        showHeader && (
          <>
            <div className='flex flex-row justify-between items-center pb-3'>
              <span className='font-bold text-4xl'>Pathways</span>
            </div>
            <Tabs>
              <Tabs.Item title="Management" to="management" />
              <Tabs.Item title="Students" to="students" />
            </Tabs>
          </>
        )
      }
      <div className='mt-4 relative h-full'>
        <Outlet context={{ setShowHeader }} />
      </div>
    </div>
  )

}

export const WorkbenchPathwaysRoute = {
  Element,
}
