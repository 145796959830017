import React from 'react'
import { useFetcher, Button, Icon } from '@design-system'

interface RemovePathwayProps {
  id: number
}

export const RemovePathway: React.FC<RemovePathwayProps> = ({ id }) => {
  const fetcher = useFetcher()
  const isLoading = fetcher.state === 'loading' && fetcher.formData?.get('id') === String(id)

  return (
    <fetcher.Form method="POST">
      <input type='hidden' name='_action' value='remove_pathway' />
      <input type='hidden' name='id' value={id} />
      <Button variant='outlined' size='xs' loading={isLoading} disabled={isLoading}>
        <Icon name='trash' size='xs' />
      </Button>
    </fetcher.Form>
  )
} 