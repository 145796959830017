import React from 'react'
import { Button, Modal, useFetcher } from '@design-system'
import { Combobox, Typography } from '@design-system'

const PATHWAY_TYPES = [
  { value: 'humanities', label: 'Humanities' },
  { value: 'stem', label: 'STEM' }
] as const

const GRADES = [
  { value: '9', label: '9th Grade' },
  { value: '10', label: '10th Grade' },
  { value: '11', label: '11th Grade' },
  { value: '12', label: '12th Grade' }
] as const

export const NewPathway: React.FC = () => {
  const fetcher = useFetcher()
  const { openModal, closeModal } = Modal.useModal()

  const isLoading = fetcher.state === 'loading' && fetcher.formData?.get('_action') === 'create_pathway'

  const handleClick = () => {
    openModal({
      title: 'New Pathway',
      Form: ({ children }) => (
        <fetcher.Form 
          method="post" 
          onSubmit={(e) => {
            e.preventDefault()
            const formData = new FormData(e.currentTarget)
            fetcher.submit(formData, { method: 'post' })
            closeModal()
          }}
        >
          {children}
        </fetcher.Form>
      ),
      content: (
        <div className="flex flex-col px-4 gap-2">
          <input type="hidden" name="_action" value="create_pathway" />
          <div className="flex flex-col gap-2">
            <Typography asChild variant="callout" weight="bold">
              <label htmlFor="title">Title</label>
            </Typography>
            <input
              type="text"
              id="title"
              name="title"
              className="w-full h-12 px-4 py-3 rounded-md border-2 border-gray-30 bg-white dark:bg-gray-90 hover:bg-gray-5 dark:hover:bg-gray-100 text-base placeholder:text-gray-80 focus:outline-none focus:border-accent focus:ring-1 focus:ring-accent disabled:cursor-not-allowed disabled:opacity-50"
              placeholder="Title"
              required
            />
          </div>
      
          <div className="flex flex-col gap-2">
            <Typography asChild variant="callout" weight="bold">
              <label htmlFor="type">Type</label>
            </Typography>
            <Combobox name="type" defaultValue={PATHWAY_TYPES[0].value} required>
              {PATHWAY_TYPES.map(type => (
                <Combobox.Item key={type.value} value={type.value}>
                  {type.label}
                </Combobox.Item>
              ))}
            </Combobox>
          </div>
      
          <div className="flex flex-col gap-2">
            <Typography asChild variant="callout" weight="bold">
              <label htmlFor="grade">Default Grade</label>
            </Typography>
            <Combobox name="gradeDefault" defaultValue={GRADES[0].value} required>
              {GRADES.map(grade => (
                <Combobox.Item key={grade.value} value={grade.value}>
                  {grade.label}
                </Combobox.Item>
              ))}
            </Combobox>
          </div>
        </div>
      ),
      footer: (
        <div className="flex justify-end gap-4">
          <Button variant="outlined" size='lg' type="button" onClick={closeModal}>
            Cancel
          </Button>
          <Button type="submit" size='lg' loading={isLoading} disabled={isLoading}>
            Save
          </Button>
        </div>
      ),
      size: 'md'
    })
  }

  return (
    <Button size='sm' onClick={handleClick}>
      <span>New pathway</span>
    </Button>
  )
} 