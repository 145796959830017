import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Button, useFetcher, Combobox, Typography } from '@design-system'

const GRADES = [
  { value: 9, label: '9th Grade' },
  { value: 10, label: '10th Grade' },
  { value: 11, label: '11th Grade' },
  { value: 12, label: '12th Grade' }
]

export const BasicDetails = ({ pathway, types = [] }) => {
  const fetcher = useFetcher()
  const [isEditing, setIsEditing] = useState(false)
  const initialValuesRef = useRef({
    title: pathway.title,
    type: pathway.type,
    grade: pathway.grade
  })
  const [currentValues, setCurrentValues] = useState({
    title: pathway.title,
    type: pathway.type,
    grade: pathway.grade
  })

  const isLoading = fetcher.state !== 'idle' && fetcher.formData?.get('pathwayId') === String(pathway.id)

  // Reset state when pathway prop changes
  useEffect(() => {
    initialValuesRef.current = {
      title: pathway.title,
      type: pathway.type,
      grade: pathway.grade
    }
    setCurrentValues({
      title: pathway.title,
      type: pathway.type,
      grade: pathway.grade
    })
  }, [pathway])

  // Close edit mode when submission is complete
  useEffect(() => {
    if (fetcher.state === 'idle' && fetcher.data) {
      setIsEditing(false)
    }
  }, [fetcher.state, fetcher.data])

  const handleChange = (field, value) => {
    setCurrentValues(prev => ({
      ...prev,
      [field]: value
    }))
  }

  const hasChanges = () => {
    return JSON.stringify(currentValues) !== JSON.stringify(initialValuesRef.current)
  }

  if (isEditing) {
    return (
      <fetcher.Form method="POST" className='flex flex-col gap-4'>
        <input type="hidden" name="_action" value="update_pathway" />
        <input type="hidden" name="pathwayId" value={pathway.id} />
        <div className="flex flex-row gap-2 justify-between items-center">
          <Typography variant="heading-6" weight="bold">General Information</Typography>
          <div className='flex flex-row gap-2'>
            <Button type="button" variant="secondary" size='sm' onClick={() => setIsEditing(false)}>
              Cancel
            </Button>
            <Button 
              type='submit' 
              size='sm'
              disabled={isLoading || !hasChanges()}
              loading={isLoading}
            >
              Save
            </Button>
          </div>
        </div>
        <div className="flex flex-row items-center pt-4 border-t border-alpha/10">
          <Typography variant="body" weight="bold" className='w-1/4'>Title</Typography>
          <input 
            id="title" 
            name="title" 
            type="text" 
            className="w-full h-12 px-4 py-3 rounded-md border-2 border-gray-30 bg-white dark:bg-gray-90 hover:bg-gray-5 dark:hover:bg-gray-100 text-base placeholder:text-gray-80 focus:outline-none focus:border-accent focus:ring-1 focus:ring-accent disabled:cursor-not-allowed disabled:opacity-50"
            placeholder="Title" 
            defaultValue={pathway.title} 
            onChange={(e) => handleChange('title', e.target.value)}
            required 
          />
        </div>
        <div className="flex flex-row items-center pt-4 border-t border-alpha/10">
          <Typography variant="body" weight="bold" className='w-1/4'>Type</Typography>
          <Combobox 
            name="type" 
            defaultValue={pathway.type} 
            className='w-full' 
            required
            onChange={(e) => handleChange('type', e.target.value)}
          >
            {types.map(type => (
              <Combobox.Item key={type.id} value={type.id}>{type.title}</Combobox.Item>
            ))}
          </Combobox>
        </div>
        <div className="flex flex-row items-center pt-4 border-t border-alpha/10">
          <Typography variant="body" weight="bold" className='w-1/4'>Default Grade</Typography>
          <Combobox 
            name="defaultGrade" 
            defaultValue={pathway.grade} 
            className='w-full' 
            required
            onChange={(e) => handleChange('grade', e.target.value)}
          >
            {GRADES.map(grade => (
              <Combobox.Item key={grade.value} value={grade.value}>{grade.label}</Combobox.Item>
            ))}
          </Combobox>
        </div>
      </fetcher.Form>
    )
  }

  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-row gap-2 justify-between items-center">
        <Typography variant="heading-6" weight="bold">General Information</Typography>
        <Button variant="secondary" size='sm' onClick={() => setIsEditing(true)}>	
          Edit
        </Button>
      </div>
      <div className="flex flex-row items-center pt-4 border-t border-alpha/10">
        <Typography variant="body" weight="bold" className='w-1/4'>Title</Typography>
        <Typography variant="body">{pathway.title}</Typography>
      </div>
      <div className="flex flex-row items-center pt-4 border-t border-alpha/10">
        <Typography variant="body" weight="bold" className='w-1/4'>Type</Typography>
        <Typography variant="body">{types.find(t => t.id === pathway.type)?.title}</Typography>
      </div>
      <div className="flex flex-row items-center pt-4 border-t border-alpha/10">
        <Typography variant="body" weight="bold" className='w-1/4'>Default Grade</Typography>
        <Typography variant="body">{GRADES.find(g => g.value === pathway.grade)?.label || 'N/A'}</Typography>
      </div>
    </div>
  )
}
BasicDetails.propTypes = {
  pathway: PropTypes.shape({
    id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    grade: PropTypes.number.isRequired,
  }),
  types: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  })),
}
