import React from 'react'
import axios from 'axios'
import { useThisPageTitle } from '@hooks/usePageTitle'
import { TextField } from '@designsystem'
import { Button, Checkbox, unstable_Tooltip as Tooltip, Icon, Typography, useFetcher } from '@design-system'
import { useLoaderData, Form, useSubmit, redirect } from 'react-router'
import { HouseCard } from './house-card'
import serializeFormData from '@utils/serializeFormData'
import SoraLink from '@components/link'

const loader = async ({ request }) => {
  const searchParams = new URL(request.url).searchParams
  const { data } = await axios.get(`/backoffice/houses?${searchParams.toString()}`)
  if (data.redirectToMainHouse) {
    return redirect(`/houses/${data.id}`)
  }
  return data
}

const action = async ({ request }) => {
  const searchParams = new URL(request.url).searchParams
  const formData = await request.formData()

  try {
    const { data } = await axios.post(`/backoffice/houses?${searchParams.toString()}`, serializeFormData(formData))
    return data
  } catch (e) {
    return {
      toast: {
        appearance: 'error',
        message: 'Something went wrong.'
      }
    }
  }
}

function Element() {
  useThisPageTitle('All Houses')
  const submit = useSubmit()
  const fetcher = useFetcher()
  const loaderData = useLoaderData()
  const housesByCampus = loaderData.housesByCampus
  const canManageHouses = loaderData.canManageHouses
  const canCreateHouse = loaderData.canCreateHouse
  const isSyncing = fetcher.state !== 'idle'

  return (
    <>
      <header className="flex items-center gap-2">
        <Typography variant="heading-3" weight="bold" className="grow">Houses</Typography>
        {canCreateHouse &&
          <Button asChild size="sm" variant="outlined">
            <SoraLink to="/houses/new">New House</SoraLink>
          </Button>
        }
        {canManageHouses &&
          <fetcher.Form method="POST">
            <Tooltip content="Sync all houses events GCalendar attendees">
              <Button
                type="submit"
                size="sm"
                loading={isSyncing}
              >
                Sync houses
              </Button>
            </Tooltip>
          </fetcher.Form>
        }
      </header>
      <Form method="GET" onChange={e => submit(e.currentTarget)} className="mt-4 mb-8 flex flex-col md:flex-row items-end md:items-center gap-4">
        <div className="w-full md:max-w-xs">
          <TextField
            name="search"
            startAdornment={<Icon name="search" size="sm" />}
            placeholder="Search for house"
            fullWidth
            autoFocus
          />
        </div>
        {canManageHouses && (
          <Checkbox name="archived" label="Show archived" />
        )}
      </Form>
      <section>
        <ul className="space-y-8">
          {housesByCampus.map(campus => (
            <li key={campus.cohort_id} className="space-y-4">
              <Typography variant="heading-6" weight="bold">{campus.cohort_title} Campus</Typography>
              <ul className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-4">
                {campus.houses.map(house => (
                  <HouseCard key={house.id} house={house} canManageHouses={canManageHouses} isSyncingAll={isSyncing} />
                ))}
              </ul>
            </li>
          ))}
          {housesByCampus.length == 0 && (
            <p>No results</p>
          )}
        </ul>
      </section>
    </>
  )
}

export const HousesIndexRoute = {
  loader,
  action,
  Element,
}
