import React from 'react'
import { Typography } from '@design-system'
import SoraLink from '@components/link'
import { CopyButton } from './copy-button'
import { RegistrationChanges } from '.'

type CellStudentNameProps = Pick<RegistrationChanges, 'student'>['student']

export const CellStudentName = ({ email, name, id, grade }: CellStudentNameProps) => (
  <CopyButton content={email}>
    <div>
      <Typography variant="callout" weight="bold" className="whitespace-nowrap">
        <SoraLink to={`/students/${id}`} className="hover:underline">{name}</SoraLink>
      </Typography>
      <Typography variant="callout" className="text-gray-80 dark:text-gray-40 whitespace-nowrap">
        {grade}
      </Typography>
    </div>
  </CopyButton>
)
