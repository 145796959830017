import React from 'react'
import { ActionFunctionArgs, LoaderFunctionArgs, redirect, useLoaderData, useNavigate } from 'react-router'
import axios from 'axios'
import utcToZonedTime from 'date-fns-tz/utcToZonedTime'
import serializeFormData from '@utils/serializeFormData'
import { Dialog, Typography, Button, Combobox, unstable_Datepicker as Datepicker, useFetcher, Icon, IconProps, unstable_RadioGroup as RadioGroup, Pill } from '@design-system'
import { TextField } from '@designsystem'
import SlateTextarea from '@components/forms/slate-textarea'

async function loader({ request, params }: LoaderFunctionArgs) {
  const { houseId, reportId, reportItemId } = params
  const searchParams = new URL(request.url).searchParams
  const { data } = await axios.get(`/backoffice/houses/${houseId}/overview/${reportId}/${reportItemId}/note?${searchParams.toString()}`)
  return data
}

async function action({ request, params }: ActionFunctionArgs) {
  const { houseId, reportId, reportItemId } = params
  const formData = await request.formData()
  const { data } = await axios.post(`/backoffice/houses/${houseId}/overview/${reportId}/${reportItemId}/note`, serializeFormData(formData))
    .catch(error => {
      return {
        data: {
          toast: {
            appearance: 'error',
            message: error.response.data.error.message
          },
          errors: error.response.data.errors,
        },
      }
    })
  if (data.success) {
    return redirect('..')
  }
  return data
}

interface CategoryProps {
  id: number
  title: string
  color: string
}

interface LoaderDataProps {
  title: string
  canDelete: boolean
  categories: CategoryProps[]
  sentiments: Array<{ value: string, label: string, icon: IconProps['name'] }>
  peopleInvolved: Array<{ value: string, label: string }>
  mediums: Array<{ value: string, label: string, icon: IconProps['name'] }>
  initialValues: {
    visibility: string
    title?: string
    categories: string[]
    dueAt: string
    sentiment: string
    medium: string
    peopleInvolved: string[]
    body: any[]
  }
}

function Element() {
  const data = useLoaderData() as LoaderDataProps
  const navigate = useNavigate()
  const fetcher = useFetcher()
  const formErrors = fetcher.data?.errors || []
  const initialDate = utcToZonedTime(data.initialValues.dueAt, 'UTC')
  return (
    <Dialog
      open={true}
      onClose={() => navigate('../')}
    >
      <Dialog.Content className="px-0">
        <Dialog.Header className='px-6 pb-2'>
          <Dialog.Title>
            Create a Note
          </Dialog.Title>
          <Dialog.Description>Document your actions in student notes for future reference.</Dialog.Description>
        </Dialog.Header>
        <fetcher.Form method="POST" noValidate>
          <Dialog.Body className="gap-0">
            <div className="border-t px-6 pt-3 pb-1 border-gray-30 dark:border-gray-70">
              <Combobox
                variant="borderless"
                name="categories[]"
                placeholder="Pick note type"
                multiple
                renderMultipleItem={(item) => {
                  const category = data.categories.find(c => String(c.id) === String(item))
                  return <Pill size="sm" color={category?.color}>{category?.title}</Pill>
                }}
                required
                defaultValue={data.initialValues.categories}
                search={<Combobox.Search placeholder="Search..." />}
                error={formErrors.find(err => err.fieldName === 'categories')?.message}

              >
                {data.categories.filter(item => item.isActive || data.initialValues.categories.find(c => String(c) === String(item.id)))?.map(({ id, title }) => (
                  <Combobox.Item key={id} value={String(id)}>{title}</Combobox.Item>
                ))}
              </Combobox>
            </div>
            {data.initialValues.title && (
              <div className="px-6 py-2 border-t border-gray-30 dark:border-gray-70">
                <TextField name="title" placeholder="Title" defaultValue={data.initialValues.title} />
              </div>
            )}
            <div className="space-y-1">
              <SlateTextarea
                /* @ts-ignore: Unreachable code error */
                id="body"
                name="body"
                value={data.initialValues.body}
                placeholderText="Add context..."
                className="h-36 rounded-none px-2"
              />
              {formErrors.find(err => err.fieldName === 'body')?.message && <Typography variant="footnote" color="danger">{formErrors.find(err => err.fieldName === 'body')?.message}</Typography>}
            </div>
            <div className="flex flex-wrap gap-2 py-3 mt-1 mb-3 px-6 border-b border-gray-30 dark:border-gray-70">
              <Combobox variant="pill" name="sentiment" required placeholder="Sentiment" defaultValue={data.initialValues.sentiment} search={<Combobox.Search placeholder="Search..." />} error={formErrors.find(err => err.fieldName === 'sentiment')?.message}>
                {data.sentiments?.map(({ value, label, icon }) => (
                  <Combobox.Item key={value} value={value}>
                    <Icon size="xs" name={icon} className="mr-1.5" />
                    {label}
                  </Combobox.Item>
                ))}
              </Combobox>
              <div className="space-y-1">
                <Datepicker
                  variant="pill"
                  name='dueAt'
                  /* @ts-ignore: Unreachable code error */
                  defaultSelected={initialDate}
                />
                {formErrors.find(err => err.fieldName === 'date')?.message && <Typography variant="footnote" color="danger">{formErrors.find(err => err.fieldName === 'date')?.message}</Typography>}
              </div>
              <Combobox name="peopleInvolved[]" multiple variant="pill" placeholder="People Involved (optional)" defaultValue={data.initialValues.peopleInvolved} search={<Combobox.Search placeholder="Search..." />} error={formErrors.find(err => err.fieldName === 'peopleInvolved')?.message}
                renderMultipleItem={(item, index) => {
                  const p = data.peopleInvolved.find(c => String(c.value) === String(item))
                  return <span>{index > 0 && <span className="-ml-2">, </span>}{p?.label}</span>
                }}>
                {data.peopleInvolved?.map(({ value, label }) => (
                  <Combobox.Item key={value} value={value}>{label}</Combobox.Item>
                ))}
              </Combobox>
              <Combobox name="medium" variant="pill" placeholder="Medium (optional)" defaultValue={data.initialValues.medium} search={<Combobox.Search placeholder="Search..." />} error={formErrors.find(err => err.fieldName === 'medium')?.message}>
                {data.mediums?.map(({ value, label, icon }) => (
                  <Combobox.Item key={value} value={value}>
                    <Icon size="xs" name={icon} className="mr-1.5" />
                    {label}
                  </Combobox.Item>
                ))}
              </Combobox>
            </div>
            <div className="flex flex-col gap-1.5 px-6">
              <Typography variant="callout" weight="bold">Visibility</Typography>
              <RadioGroup name="visibility" defaultValue={data.initialValues.visibility}>
                <RadioGroup.Item value="all" label="All Sora Employees" />
                <RadioGroup.Item value="restricted" label="Restricted (visible only to note creator, Deans and School Directors)" />
              </RadioGroup>
              {formErrors.find(err => err.fieldName === 'visibility') && <Typography color="danger">{formErrors.find(err => err.fieldName === 'visibility').message}</Typography>}
            </div>
          </Dialog.Body>
          <Dialog.Footer className="px-6">
            <Button type="button" variant="outlined" onClick={() => navigate('../')}>Skip</Button>
            <Button type="submit" loading={fetcher.state === 'submitting'}>Save</Button>
          </Dialog.Footer>
        </fetcher.Form>
      </Dialog.Content>
    </Dialog >
  )
}


export const HouseOverviewReportNoteDialogRoute = {
  loader,
  action,
  Element,
}


