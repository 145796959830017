{/* prettier-ignore */ }
import 'regenerator-runtime/runtime'
import React from 'react'
import axios from 'axios'
import { SWRConfig } from 'swr'
import PropTypes from 'prop-types'
import * as Sentry from '@sentry/react'
import { captureException } from '@sentry/react'
import { RouterProvider } from 'react-router/dom'
import { Route, createBrowserRouter, createRoutesFromElements } from 'react-router'
import { PageTitleProvider } from '@hooks/usePageTitle'
import { ConfirmModalProvider } from '@hooks/useConfirmModal/provider'
import { Toaster, unstable_TooltipBase as TooltipBase } from '@design-system'

/**
 * Legacy Routes
*/
import ErrorBoundary, { ErrorComponent } from '@components/error-boundary'
import ReleaseProgressReport, { loader as releaseProgressReportLoader } from '@routes/admin/workbench/reports/progress.jsx'
import AttendanceFacultyRoute, {
  loader as attendanceFacultyLoader,
} from '@routes/employee/attendances/calendar'
import FeatureFlags from '@routes/admin/workbench/feature-flags'
import Permissions from '@routes/admin/workbench/permissions'
import SignInRoute, { loader as signInLoader } from '@routes/signin'
import TemplateExpeditionLibrary from '@routes/template/library'
import { loader as templateExpeditionLibraryLoader } from '@routes/template/library/loader'
import { action as templateExpeditionLibraryAction } from '@routes/template/library/action'
import EmployeeAtendancesRoute from '@routes/employee/attendances'
import EmployeeAttendancesReportRoute from '@routes/employee/attendances/report'
import { loader as employeeAttendancesReportLoader } from '@routes/employee/attendances/report/loader'
import EnrolmentsRoute from '@routes/admin/enrolments'
import enrolmentsLoader from '@routes/admin/enrolments/loader'
import enrolmentsAction from '@routes/admin/enrolments/action'
import Impersonate from '@routes/employee/impersonate'
import ImpersonateStudents, {
  loader as impersonateStudentsLoader,
  action as impersonateStudentsAction
} from '@routes/employee/impersonate/students'
import ImpersonateGuardians, {
  loader as impersonateGuardiansLoader,
  action as impersonateGuardiansAction
} from '@routes/employee/impersonate/guardians'

import WorkbenchUnitsList, { loader as WorkbenchUnitsListLoader } from '@routes/admin/workbench/units/list'
import WorkbenchUnitsRoute from '@routes/admin/workbench/units/index'
import Admissions, { loader as admissionsLoader, action as admissionsAction } from '@routes/admin/workbench/admissions/admissions'
import EditUnitWorkbenchRoute, { loader as EditUnitWorkbenchLoader, action as EditUnitWorkbenchAction } from '@routes/admin/workbench/units/edit'
import NewUnitWorkbenchRoute, { loader as NewUnitWorkbenchLoader, action as NewUnitWorkbenchAction } from '@routes/admin/workbench/units/new'
import WorkbenchIndex from '@routes/admin/workbench/index'
import WorkbenchHeartbeatRoute from '@routes/admin/workbench/hearbeat'
import WorkbenchEmployeesRoute from '@routes/admin/workbench/employees'
import WorkbenchEmployeeProfile from '@routes/admin/workbench/employees/profile/index'
import WorkbenchEmployeeNew from '@routes/admin/workbench/employees/profile/new'
import WorkbenchEmployeeTable from '@routes/admin/workbench/employees/table/index'
import WorkbenchMailchimpRoute from '@routes/admin/workbench/mailchimp'
import WorkbenchAdminRoute from '@routes/admin/workbench/admin-actions'
import { IndexNoAuthRoute, loader as indexNoAuthLoader } from './routes'
import { NavigateCycle } from '@hooks/useNavigateWithCycle'
import WorkbenchOnboardingAddFamiliesToGroupRoute, {
  loader as workbenchOnboardingAddFamiliesToGroupLoader,
  action as workbenchOnboardingAddFamiliesToGroupAction,
} from '@routes/admin/workbench/onboarding/families/$campusFilter/add-families-to-group'
import WorkbenchOnboardingCampus$OnboardingGroupIdContentRoute, {
  loader as workbenchOnboardingCampus$OnboardingGroupIdContentLoader,
} from '@routes/admin/workbench/onboarding/$onboardingGroupId/content'
import WorkbenchOnboardingChecklistNewRoute, {
  loader as workbenchOnboardingChecklistNewRouteLoader,
  action as workbenchOnboardingChecklistNewRouteAction
} from '@routes/admin/workbench/onboarding/$onboardingGroupId/content/checklist/new'
import WorkbenchOnboardingGroupsNewRoute, {
  loader as workbenchOnboardingGroupsNewLoader,
  action as workbenchOnboardingGroupsNewAction
} from '@routes/admin/workbench/onboarding/groups/$campusFilter/new'
import WorkbenchOnboardingGroupDuplicatedGroupModal$onboardingIdRoute, {
  loader as workbenchOnboardingGroupDuplicatedGroupModal$onboardingIdLoader
} from '@routes/admin/workbench/onboarding/groups/$campusFilter/duplicated-group-modal/$onboardingGroupId'
import WorkbenchOnboardingChecklistEditRoute, {
  loader as workbenchOnboardingChecklistEditLoader,
  action as workbenchOnboardingChecklistEditAction
} from '@routes/admin/workbench/onboarding/$onboardingGroupId/content/checklist/$checklistId/$userViewType/edit'
import WorkbenchOnboardingGroups$OnboardingGroupIdRoute, {
  loader as workbenchOnboardingGroups$OnboardingGroupIdLoader,
  action as workbenchOnboardingGroups$OnboardingGroupIdAction,
} from '@routes/admin/workbench/onboarding/groups/$campusFilter/edit/$onboardingGroupId'
import WorkbenchCannyRoute from '@routes/admin/workbench/canny'
import WorkbenchCannySyncUsersRoute from '@routes/admin/workbench/canny/sync-users'
import { action as workbenchCannySyncUsersAction } from '@routes/admin/workbench/canny/sync-users/action'
import StudentsRequests, { loader as studentsRequestsLoader } from '@routes/admin/reports/students/requests'
import ExperiencesRosterReportsRoutes from '@routes/admin/reports/experiences-roster'
import experiencesRosterReportsLoader from '@routes/admin/reports/experiences-roster/loader'
import StudentMissingUnits, { loader as studentMissingUnitsLoader } from '@routes/admin/reports/students/missing/units'
import UnitsAbilitiesCompletion, { loader as unitsAbilitiesCompletionLoader } from '@routes/admin/reports/units-abilities-completion'
import StudentAvailabilityReport, { loader as studentAvailabilityReportLoader } from '@routes/admin/reports/students/availability'
import MaterialsReportRoute, { loader as materialsReportLoader } from '@routes/admin/reports/materials'
import { HousesIndexRoute } from '@features/houses._index'
import { HouseNewRoute } from '@features/houses.new'
import { HouseRoute } from '@features/houses.$houseId'
import { HouseOverviewRoute } from '@features/houses.$houseId.overview'
import { HouseOverviewIndexRoute } from '@features/houses.$houseId.overview._index'
import { HouseOverviewReportRoute } from '@features/houses.$houseId.overview.$reportId'
import { HouseOverviewReportNoteDialogRoute } from '@features/houses.$houseId.overview.$reportId.note'
import { HouseStudentsRoute } from '@features/houses.$houseId.students'
import { HouseAttendancesRoute } from '@features/houses.$houseId.attendances'
import { HouseAttendanceEventRoute } from '@features/houses.$houseId.attendances.$eventId'
import { HouseEditRoute } from '@features/houses.edit.$houseId'
import { FeatureFlagSettingsLabsRoute } from '@routes/admin/workbench/feature-flag/$feature_flag_id/sora-labs-settings'
import AdmissionsConfig from '@routes/admin/workbench/admissions/config/admissions-config'
import AdmissionsCopyConfig, { loader as admissionsCopyConfigLoader, action as admissionsCopyConfigAction } from '@routes/admin/workbench/admissions/config/admissions-copy-config'
import AdmissionsStartDatesConfig, { loader as admissionsStartDatesConfigLoader, action as admissionsStartDatesConfigAction } from '@routes/admin/workbench/admissions/config/admissions-start-dates-config'

/**
 * Features
 */
import { RootRoute } from '@features/_root'
import { RootAuthenticatedRoute } from '@features/_root._authenticated'
import { HomeRoute } from '@features/home'
import { FacilitateExperienceTaskRoute } from '@features/facilitate_.$type_.$experienceId_.tasks.$taskId'
import { FacilitateExperienceAttendanceRoute } from '@features/facilitate_.$type_.$experienceId_.attendance'
import { FacilitateExperienceAttendanceDefaultRoute } from '@features/facilitate_.$type_.$experienceId_.attendance.default'
import { FacilitateExperienceAttendanceDefaultSessionRoute } from '@features/facilitate_.$type_.$experienceId_.attendance.default.$sessionId'
import { FacilitateExperienceAttendanceDefaultSessionZoomReportRoute } from '@features/facilitate_.$type_.$experienceId_.attendance.default.$sessionId.zoom-report'
import { FacilitateExperienceAttendanceAggregatedRoute } from '@features/facilitate_.$type_.$experienceId_.attendance.aggregated'
import { FacilitateExperienceTaskStudentRoute } from '@features/facilitate_.$type_.$experienceId_.tasks.$taskId.students.$studentId'
import { FacilitateExperienceTaskStudentMetricsRoute } from '@features/facilitate_.$type_.$experienceId_.tasks.$taskId.students.$studentId.metrics'
import { FacilitateExperienceTaskStudentDeadlineExtensionRoute } from '@features/facilitate_.$type_.$experienceId_.tasks.$taskId.students.$studentId.deadline-extension'
import { FacilitateExperienceTaskStudentFeedbackRoute } from '@features/facilitate_.$type_.$experienceId_.tasks.$taskId.students.$studentId.feedback'
import { FacilitateExperienceTaskStudentCommentBankRoute } from '@features/facilitate_.$type_.$experienceId_.tasks.$taskId.students.$studentId.comment-bank'
import { FacilitateExperienceTaskStudentCommentBankCreateRoute } from '@features/facilitate_.$type_.$experienceId_.tasks.$taskId.students.$studentId.comment-bank.preset-comment.$commentId'
import { FacilitateExperienceTaskStudentMidCycleReportAiRoute } from '@features/facilitate_.$type_.$experienceId_.tasks.$taskId.students.$studentId.mid-cycle-report-ai'
import { HouseAttendanceEventZoomReportRoute } from '@features/houses.$houseId.attendances.$eventId.zoom-report'
import { OnboardingGroupsRoute } from '@features/workbench.onboarding.groups'
import { OnboardingGroupsCampusId } from '@features/workbench.onboarding.groups.$campusId'
import { AppSsoRoute } from '@features/app-sso'
import { Onboarding$TodoIdRoute } from '@features/workbench.onboarding.$onboardingGroupId.content.checklist.$checklistId.$userViewType.todo.$todoId'
import { OnboardingUserTypeRoute } from '@features/workbench.onboarding.$onboardingGroupId.content.checklist.$checklistId.$userType'
import { OnboardingChecklistIdRoute } from '@features/workbench.onboarding.$onboardingGroupId.content.checklist.$checklistId'
import { WorkbenchRoute } from '@features/workbench._index'
import { WorkbenchMathPlacementRoute } from '@features/workbench.math-placement'
import { WorkbenchMathPlacementTimelineRoute } from '@features/workbench.math-placement.timeline'
import { WorkbenchMathPlacementIndexRoute } from '@features/workbench.math-placement._index'
import { WorkbenchMathPlacementStudentsRoute } from '@features/workbench.math-placement.students'
import { WorkbenchOnboardingFamiliesReportRoute } from '@features/workbench.onboarding.families'
import { WorkbenchOnboardingFamiliesReportFilterRoute } from '@features/workbench.onboarding.families.$campusFilter'
import { WorkbenchOnboardingRoute } from '@features/workbench.onboarding'
import { WorkbenchPathwaysIndexRoute } from '@features/workbench.pathways._index'
import { WorkbenchPathwaysRoute } from '@features/workbench.pathways'
import { WorkbenchPathwaysManagementIndexRoute } from '@features/workbench.pathways.management._index'
import { WorkbenchPathwaysManagementPathwayIdRoute } from '@features/workbench.pathways.management.$pathwayId'
import { WorkbenchPathwaysStudentsRoute } from '@features/workbench.pathways.students'
import { WorkbenchStudentHearbeatServerRoute } from '@features/workbench.student-heartbeat-server'
import { WorkbenchStudentHearbeatServerBasicSetupRoute } from '@features/workbench.student-heartbeat-server.basic-setup'
import { WorkbenchStudentHearbeatServerStudentsRoute } from '@features/workbench.student-heartbeat-server.students'
import { WorkbenchStudentHearbeatServerHousesRoute } from '@features/workbench.student-heartbeat-server.houses'
import { WorkbenchStudentHearbeatServerExperiencesRoute } from '@features/workbench.student-heartbeat-server.experiences'
import { WorkbenchStudentHearbeatServerUsersRoute } from '@features/workbench.student-heartbeat-server.users'
import { WorkbenchStudentHearbeatServerEmployeesRoute } from '@features/workbench.student-heartbeat-server.employees'
import { WorkbenchStudentHearbeatServerJobsRoute } from '@features/workbench.student-heartbeat-server.jobs'
import { WorkbenchStudentHearbeatServerJobsLogsRoute } from '@features/workbench.student-heartbeat-server.jobs.$jobId'
import { WorkbenchZoomRoute } from '@features/workbench.zoom'
import { WorkbenchGoogleCalendarSyncRoute } from '@features/workbench.google-calendar-sync'
import { WorkbenchRosettaStoneRoute } from '@features/workbench.rosetta-stone'
import { WorkbenchRosettaStoneUpdateCreditRoute } from '@features/workbench.rosetta-stone.update-credit'
import { WorkbenchRosettaStoneManageUsersRoute } from '@features/workbench.rosetta-stone.manage-users'
import { NewPasswordRoute } from '@routes/recover-password'
import { ReportsRegistrationChangesRoute } from '@features/reports.registration-changes'

axios.defaults.baseURL = '/api'
axios.defaults.headers.common['X-Frontend-Id'] = import.meta.env.VITE_APP_GITHUB_SHA
axios.defaults.validateStatus = function () {
  return true
}

function App() {
  const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(
    createBrowserRouter
  )
  const router = sentryCreateBrowserRouter(
    createRoutesFromElements(
      <Route path="/" element={<RootRoute.Element />} loader={RootRoute.loader} errorElement={<ErrorComponent />}>
        <Route path="/" element={<RootAuthenticatedRoute.Element />} loader={RootAuthenticatedRoute.loader} action={RootAuthenticatedRoute.action}>
          <Route index element={<NavigateCycle to="/home" />} />
          <Route path="app-sso" loader={AppSsoRoute.loader} />
          <Route path="home" element={<HomeRoute.Element />} loader={HomeRoute.loader} />
          <Route path="students" lazy={() => import('@features/students')}>
            <Route path="graduation/:studentId" lazy={() => import('@features/students_.graduation.$studentId')} />
          </Route>
          <Route path="students/:studentId" lazy={() => import('@features/students_.$studentId')}>
            <Route index element={<NavigateCycle to="overview" />} />
            <Route path="overview" lazy={() => import('@features/students_.$studentId.overview')} />
            <Route path="details" lazy={() => import('@features/students_.$studentId.details')}>
              <Route path="add-existing-guardian" lazy={() => import('@features/students_.$studentId.details.add-existing-guardian')} />
              <Route path="house-assignment" lazy={() => import('@features/students_.$studentId.details.house-assignment')} />
            </Route>
            <Route path="notes" lazy={() => import('@features/students_.$studentId.notes')}>
              <Route path="form/:noteId?" lazy={() => import('@features/students_.$studentId.notes.form.($noteId)')} />
              <Route path="view/:noteId" lazy={() => import('@features/students_.$studentId.notes.view.$noteId')} />
            </Route>
            <Route path="program/:schoolStage?" lazy={() => import('@features/students_.$studentId.program.($schoolStage)')}>
              <Route path="overview" lazy={() => import('@features/students_.$studentId.program.$schoolStage.overview')}>
                <Route path="abilities/:abilityId" lazy={() => import('@features/students_.$studentId.program.$schoolStage.overview.abilities.$abilityId')} />
              </Route>
              <Route path="mentorship-capstone" lazy={() => import('@features/students_.$studentId.program.$schoolStage.mentorship-capstone')} />
              <Route path=":subjectId" lazy={() => import('@features/students_.$studentId.program.$schoolStage.$subjectId')}>
                <Route path="details/:topicId" lazy={() => import('@features/students_.$studentId.program.$schoolStage.$subjectId.details.$topicId')} />
                <Route path="exemption/:topicId" lazy={() => import('@features/students_.$studentId.program.$schoolStage.$subjectId.exemption.$topicId')} />
                <Route path="external-elective/:topicId" lazy={() => import('@features/students_.$studentId.program.$schoolStage.$subjectId.external-elective.$topicId')} />
              </Route>
            </Route>
            <Route path="reports" lazy={() => import('@features/students_.$studentId.reports')} />
            <Route path="status" lazy={() => import('@features/students_.$studentId.status')}>
              <Route path="motivation" lazy={() => import('@features/students_.$studentId.status.motivation')} />
              <Route path="update-leave-of-absence" lazy={() => import('@features/students_.$studentId.status.update-leave-of-absence')} />
              <Route path="revoke-hold" lazy={() => import('@features/students_.$studentId.status.revoke-hold')} />
            </Route>
            <Route path="documents" lazy={() => import('@routes/employee/students/$studentId/documents')} />
            <Route path="learning-availability" lazy={() => import('@features/students_.$studentId.learning-availability')} />
            <Route path="learning-hours" lazy={() => import('@features/students_.$studentId.learning-hours')} />
            <Route path="transcript" lazy={() => import('@routes/employee/students/$studentId/transcript')} />
          </Route>
          <Route path="facilitate" lazy={() => import('@features/facilitate')}>
            <Route index element={<NavigateCycle to="/facilitate/expeditions-learning-goals" />} />
            <Route path=":type" lazy={() => import('@features/facilitate.$type')}>
              <Route path=":experienceId" lazy={() => import('@features/facilitate.$type.$experienceId')} />
            </Route>
          </Route>
          <Route path='facilitate/:type/:experienceId/attendance' element={<FacilitateExperienceAttendanceRoute.Element />} loader={FacilitateExperienceAttendanceRoute.loader}>
            <Route path='default' element={<FacilitateExperienceAttendanceDefaultRoute.Element />} loader={FacilitateExperienceAttendanceDefaultRoute.loader}>
              <Route path=':sessionId' element={<FacilitateExperienceAttendanceDefaultSessionRoute.Element />} loader={FacilitateExperienceAttendanceDefaultSessionRoute.loader} action={FacilitateExperienceAttendanceDefaultSessionRoute.action}>
                <Route path="zoom-report" element={<FacilitateExperienceAttendanceDefaultSessionZoomReportRoute.Element />} loader={FacilitateExperienceAttendanceDefaultSessionZoomReportRoute.loader} action={FacilitateExperienceAttendanceDefaultSessionZoomReportRoute.action} />
              </Route>
            </Route>
            <Route path='aggregated' element={<FacilitateExperienceAttendanceAggregatedRoute.Element />} loader={FacilitateExperienceAttendanceAggregatedRoute.loader} />
          </Route>
          <Route path='facilitate/:type/:experienceId/tasks/:taskId' element={<FacilitateExperienceTaskRoute.Element />} loader={FacilitateExperienceTaskRoute.loader}>
            <Route path='students/:studentId' element={<FacilitateExperienceTaskStudentRoute.Element />} loader={FacilitateExperienceTaskStudentRoute.loader} action={FacilitateExperienceTaskStudentRoute.action}>
              <Route path='metrics' element={<FacilitateExperienceTaskStudentMetricsRoute.Element />} loader={FacilitateExperienceTaskStudentMetricsRoute.loader} />
              <Route path='deadline-extension' element={<FacilitateExperienceTaskStudentDeadlineExtensionRoute.Element />} loader={FacilitateExperienceTaskStudentDeadlineExtensionRoute.loader} action={FacilitateExperienceTaskStudentDeadlineExtensionRoute.action} />
              <Route path='mid-cycle-report-ai' element={<FacilitateExperienceTaskStudentMidCycleReportAiRoute.Element />} loader={FacilitateExperienceTaskStudentMidCycleReportAiRoute.loader} action={FacilitateExperienceTaskStudentMidCycleReportAiRoute.action} />
              <Route path='feedback' element={<FacilitateExperienceTaskStudentFeedbackRoute.Element />} loader={FacilitateExperienceTaskStudentFeedbackRoute.loader} action={FacilitateExperienceTaskStudentFeedbackRoute.action} errorElement={<ErrorComponent />} />
              <Route path='comment-bank' element={<FacilitateExperienceTaskStudentCommentBankRoute.Element />} loader={FacilitateExperienceTaskStudentCommentBankRoute.loader} action={FacilitateExperienceTaskStudentCommentBankRoute.action} >
                <Route path='preset-comment/:commentId' element={<FacilitateExperienceTaskStudentCommentBankCreateRoute.Element />} loader={FacilitateExperienceTaskStudentCommentBankCreateRoute.loader} action={FacilitateExperienceTaskStudentCommentBankCreateRoute.action} />
              </Route>
            </Route>
          </Route>

          <Route path="faculty-availability/:employeeId?" lazy={() => import('@features/faculty-availability.($employeeId)')} />

          <Route path="notifications" lazy={() => import('@features/notifications')}>
            <Route path="view" lazy={() => import('@features/notifications.view')}>
              <Route path=":notificationType" id="notifications.view.$notificationType" lazy={() => import('@features/notifications.view.$notificationType')}>
                <Route path=":notificationId" lazy={() => import('@features/notifications.view.$notificationType.$notificationId')} />
              </Route>
            </Route>
            <Route path="preferences" lazy={() => import('@features/notifications.preferences')} />
          </Route>

          <Route path="employee/attendances" element={<EmployeeAtendancesRoute />}>
            <Route index element={<NavigateCycle to="calendar" />} />
            <Route path="calendar" element={<AttendanceFacultyRoute />} loader={attendanceFacultyLoader} />
            <Route path="report" element={<EmployeeAttendancesReportRoute />} loader={employeeAttendancesReportLoader} />
          </Route>
          <Route path="impersonate" element={<Impersonate />}  >
            <Route path="students" element={<ImpersonateStudents />} loader={impersonateStudentsLoader} action={impersonateStudentsAction} />
            <Route path="guardians" element={<ImpersonateGuardians />} loader={impersonateGuardiansLoader} action={impersonateGuardiansAction} />
          </Route>

          <Route path="reports" lazy={() => import('@features/reports')}>
            <Route index lazy={() => import('@features/reports._index')} />
            <Route path="students/requests" element={<StudentsRequests />} loader={studentsRequestsLoader}></Route>
            <Route path="students/missing/units" element={<StudentMissingUnits />} loader={studentMissingUnitsLoader}></Route>
            <Route path="students/availability" element={<StudentAvailabilityReport />} loader={studentAvailabilityReportLoader}></Route>
            <Route path="registration-changes" element={<ReportsRegistrationChangesRoute.Element />} loader={ReportsRegistrationChangesRoute.loader} />
            <Route path="experiences-roster" element={<ExperiencesRosterReportsRoutes />} loader={experiencesRosterReportsLoader}></Route>
            <Route path="units-abilities-completion" element={<UnitsAbilitiesCompletion />} loader={unitsAbilitiesCompletionLoader}></Route>
            <Route path="materials" element={<MaterialsReportRoute />} loader={materialsReportLoader}></Route>
            <Route path="task-responses" lazy={() => import('@features/reports.task-responses')} >
              <Route index element={<NavigateCycle to="expert-overview" />} />
              <Route path="expert-overview" lazy={() => import('@features/reports.task-responses.expert-overview')} />
              <Route path="stale-tasks" lazy={() => import('@features/reports.task-responses.stale-tasks')} />
            </Route>
            <Route path="notes" lazy={() => import('@features/reports.notes')}>
              <Route path=":noteId" lazy={() => import('@features/reports.notes.$noteId')} />
            </Route>
            <Route path="master-schedules/experts/:schoolStage" lazy={() => import('@features/reports.master-schedules.experts.$schoolStage')} />
            <Route path="master-schedules/students/:schoolStage" lazy={() => import('@features/reports.master-schedules.students.$schoolStage')} />
            <Route path="learning-hours" lazy={() => import('@features/reports.learning-hours')} />
          </Route>

          <Route path='experiences' lazy={() => import('@features/experiences')}>
            <Route index element={<NavigateCycle to="./type/expedition" />} />
            <Route path='type/:type' lazy={() => import('@features/experiences._index')} />
            <Route path=':experienceId' errorElement={<ErrorComponent type="DEFAULT" />}>
              <Route path='duplicate' lazy={() => import('@features/experiences.$experienceId.duplicate')} />
            </Route>
            <Route path=":experienceId" lazy={() => import('@features/experiences.$experienceId')} errorElement={<ErrorComponent type="DEFAULT" />}>
              <Route index element={<NavigateCycle to="./edit" />} />
              <Route path="edit" lazy={() => import('@features/experiences.$experienceId.edit')} />
              <Route path="tasks" lazy={() => import('@features/experiences.$experienceId.tasks')}>
                <Route path="guidebook" lazy={() => import('@features/experiences.$experienceId.tasks.guidebook')} />
              </Route>
              <Route path="registrations" lazy={() => import('@features/experiences.$experienceId.registrations')} />
            </Route>
          </Route>

          <Route path="template">
            <Route index element={<NavigateCycle to="/template/library" />} />
            <Route path="library" element={<TemplateExpeditionLibrary />} loader={templateExpeditionLibraryLoader} action={templateExpeditionLibraryAction} />
            <Route path=":templateId?/edit" lazy={() => import('@features/template.($templateId).edit')} />
            <Route path=":templateId/use-picked-template" lazy={() => import('@features/template.$templateId.use-picked-template')} />
          </Route>

          <Route path="clubs" lazy={() => import('@features/clubs')} />
          <Route path="clubs/:clubId?/edit" lazy={() => import('@features/clubs_.($clubId).edit')} />
          <Route path="clubs/:clubId/members" lazy={() => import('@features/clubs_.$clubId.members')} />

          <Route path="houses">
            <Route index element={<HousesIndexRoute.Element />} loader={HousesIndexRoute.loader} action={HousesIndexRoute.action} />
            <Route path="new" element={<HouseNewRoute.Element />} loader={HouseNewRoute.loader} action={HouseNewRoute.action} />
            <Route path="edit/:houseId" element={<HouseEditRoute.Element />} loader={HouseEditRoute.loader} action={HouseEditRoute.action} />
            <Route path=":houseId" element={<HouseRoute.Element />} loader={HouseRoute.loader}>
              <Route index element={<NavigateCycle to="overview" />} />
              <Route path="overview" element={<HouseOverviewRoute.Element />} loader={HouseOverviewRoute.loader}>
                <Route index element={<HouseOverviewIndexRoute.Element />} loader={HouseOverviewIndexRoute.loader} />
                <Route path=":reportId" element={<HouseOverviewReportRoute.Element />} loader={HouseOverviewReportRoute.loader} action={HouseOverviewReportRoute.action}>
                  <Route path=":reportItemId/note" element={<HouseOverviewReportNoteDialogRoute.Element />} loader={HouseOverviewReportNoteDialogRoute.loader} action={HouseOverviewReportNoteDialogRoute.action} />
                </Route>
              </Route>
              <Route path="students" element={<HouseStudentsRoute.Element />} loader={HouseStudentsRoute.loader} />
              <Route path="attendances" element={<HouseAttendancesRoute.Element />} loader={HouseAttendancesRoute.loader}>
                <Route path=":eventId" element={<HouseAttendanceEventRoute.Element />} loader={HouseAttendanceEventRoute.loader} action={HouseAttendanceEventRoute.action}>
                  <Route path="zoom-report" element={<HouseAttendanceEventZoomReportRoute.Element />} loader={HouseAttendanceEventZoomReportRoute.loader} action={HouseAttendanceEventZoomReportRoute.action} />
                </Route>
              </Route>
            </Route>
          </Route>

          <Route path="cycles" lazy={() => import('@features/cycles')}>
            <Route path=":cycleId/edit" lazy={() => import('@features/cycles.$cycleId.edit')} />
          </Route>

          <Route path="workbench" element={<WorkbenchRoute.Element />} loader={WorkbenchRoute.loader} errorElement={<ErrorComponent type="DEFAULT" />}>
            <Route path="*" element={<NotFoundRoute />} />
            <Route index element={<WorkbenchIndex />} />
            <Route path="units" element={<WorkbenchUnitsRoute />}>
              <Route index element={<WorkbenchUnitsList />} loader={WorkbenchUnitsListLoader} />
              <Route path="new" element={<NewUnitWorkbenchRoute />} loader={NewUnitWorkbenchLoader} action={NewUnitWorkbenchAction} />
              <Route path="edit/:unit_id" element={<EditUnitWorkbenchRoute />} loader={EditUnitWorkbenchLoader} action={EditUnitWorkbenchAction} />
            </Route>
            <Route path="heartbeat" element={<WorkbenchHeartbeatRoute />} />
            <Route path="student-heartbeat-server" element={<WorkbenchStudentHearbeatServerRoute.Element />}>
              <Route path="basic-setup" element={<WorkbenchStudentHearbeatServerBasicSetupRoute.Element />} action={WorkbenchStudentHearbeatServerBasicSetupRoute.action} />
              <Route path="students" element={<WorkbenchStudentHearbeatServerStudentsRoute.Element />} loader={WorkbenchStudentHearbeatServerStudentsRoute.loader} action={WorkbenchStudentHearbeatServerStudentsRoute.action} />
              <Route path="houses" element={<WorkbenchStudentHearbeatServerHousesRoute.Element />} loader={WorkbenchStudentHearbeatServerHousesRoute.loader} action={WorkbenchStudentHearbeatServerHousesRoute.action} />
              <Route path="experiences" element={<WorkbenchStudentHearbeatServerExperiencesRoute.Element />} loader={WorkbenchStudentHearbeatServerExperiencesRoute.loader} action={WorkbenchStudentHearbeatServerExperiencesRoute.action} />
              <Route path="users" element={<WorkbenchStudentHearbeatServerUsersRoute.Element />} loader={WorkbenchStudentHearbeatServerUsersRoute.loader} action={WorkbenchStudentHearbeatServerUsersRoute.action} />
              <Route path="employees" element={<WorkbenchStudentHearbeatServerEmployeesRoute.Element />} loader={WorkbenchStudentHearbeatServerEmployeesRoute.loader} action={WorkbenchStudentHearbeatServerEmployeesRoute.action} />
              <Route path="jobs" element={<WorkbenchStudentHearbeatServerJobsRoute.Element />} loader={WorkbenchStudentHearbeatServerJobsRoute.loader}>
                <Route path=":jobId" element={<WorkbenchStudentHearbeatServerJobsLogsRoute.Element />} loader={WorkbenchStudentHearbeatServerJobsLogsRoute.loader} />
              </Route>
            </Route>
            <Route path="rosetta-stone" element={<WorkbenchRosettaStoneRoute.Element />}>
              <Route path="update-credit" element={<WorkbenchRosettaStoneUpdateCreditRoute.Element />} loader={WorkbenchRosettaStoneUpdateCreditRoute.loader} action={WorkbenchRosettaStoneUpdateCreditRoute.action} />
              <Route path="manage-users" element={<WorkbenchRosettaStoneManageUsersRoute.Element />} loader={WorkbenchRosettaStoneManageUsersRoute.loader} action={WorkbenchRosettaStoneManageUsersRoute.action} />
            </Route>

            <Route path="gcal-sync" element={<WorkbenchGoogleCalendarSyncRoute.Element />} loader={WorkbenchGoogleCalendarSyncRoute.loader} action={WorkbenchGoogleCalendarSyncRoute.action} />

            <Route path='experience-sessions' lazy={() => import('@features/workbench.experience-sessions')} />
            <Route path='campus-settings' lazy={() => import('@features/workbench.campus-settings')} />

            <Route path='journey-builder'>
              <Route path='processes'>
                <Route index lazy={() => import('@features/workbench.journey-builder.processes._index')} />
                <Route path='new' lazy={() => import('@features/workbench.journey-builder.processes.new')} />
                <Route path=':processId' lazy={() => import('@features/workbench.journey-builder.processes.$processId')}>
                  <Route index lazy={() => import('@features/workbench.journey-builder.processes.$processId._index')} />
                  <Route path='students' lazy={() => import('@features/workbench.journey-builder.processes.$processId.students')} />
                  <Route path="templates">
                    <Route path="config" lazy={() => import('@features/workbench.journey-builder.processes.$processId.templates.config')} />
                    <Route path="review" lazy={() => import('@features/workbench.journey-builder.processes.$processId.templates.review')} />
                  </Route>
                  <Route path="assignments" lazy={() => import('@features/workbench.journey-builder.processes.$processId.assignments')} >
                    <Route path="students" lazy={() => import('@features/workbench.journey-builder.processes.$processId.assignments.students')} />
                    <Route path="expeditions" lazy={() => import('@features/workbench.journey-builder.processes.$processId.assignments.expeditions')} />
                  </Route>
                  <Route path="schedule">
                    <Route path="config" lazy={() => import('@features/workbench.journey-builder.processes.$processId.schedule.config')} />
                    <Route path="review" lazy={() => import('@features/workbench.journey-builder.processes.$processId.schedule.review')} >
                      <Route index element={<NavigateCycle to="students" />} />
                      <Route path="students" lazy={() => import('@features/workbench.journey-builder.processes.$processId.schedule.review.students')} />
                      <Route path="experts" lazy={() => import('@features/workbench.journey-builder.processes.$processId.schedule.review.experts')} />
                      <Route path="expeditions" lazy={() => import('@features/workbench.journey-builder.processes.$processId.schedule.review.expeditions')} />
                      <Route path='overview' lazy={() => import('@features/workbench.journey-builder.processes.$processId.schedule.review.overview')} />
                    </Route>
                    <Route path="generated" lazy={() => import('@features/workbench.journey-builder.processes.$processId.schedule.generated')}>
                      <Route path="students" lazy={() => import('@features/workbench.journey-builder.processes.$processId.schedule.generated.students')} />
                      <Route path="experts" lazy={() => import('@features/workbench.journey-builder.processes.$processId.schedule.generated.experts')} />
                      <Route path="expeditions" lazy={() => import('@features/workbench.journey-builder.processes.$processId.schedule.generated.expeditions')} />
                      <Route path="missing" lazy={() => import('@features/workbench.journey-builder.processes.$processId.schedule.generated.missing')} />
                    </Route>
                  </Route>
                </Route>
              </Route>
            </Route>

            <Route path='pathways' element={<WorkbenchPathwaysRoute.Element />}>
              <Route index element={<WorkbenchPathwaysIndexRoute.Element />} />
              <Route path='management'>
                <Route index element={<WorkbenchPathwaysManagementIndexRoute.Element />} loader={WorkbenchPathwaysManagementIndexRoute.loader} action={WorkbenchPathwaysManagementIndexRoute.action} />
                <Route path=':pathwayId' element={<WorkbenchPathwaysManagementPathwayIdRoute.Element />} loader={WorkbenchPathwaysManagementPathwayIdRoute.loader} action={WorkbenchPathwaysManagementPathwayIdRoute.action} />
              </Route>
              <Route path='students' element={<WorkbenchPathwaysStudentsRoute.Element />} loader={WorkbenchPathwaysStudentsRoute.loader} action={WorkbenchPathwaysStudentsRoute.action} />
            </Route>
            
            <Route path="employees" element={<WorkbenchEmployeesRoute />} >
              <Route index element={<WorkbenchEmployeeTable />} />
              <Route path=":employee_id" element={<WorkbenchEmployeeProfile />} />
              <Route path="new" element={<WorkbenchEmployeeNew />} />
            </Route>
            <Route path="onboarding" element={<WorkbenchOnboardingRoute.Element />} action={WorkbenchOnboardingRoute.action}>
              <Route path="families" element={<WorkbenchOnboardingFamiliesReportRoute.Element />} loader={WorkbenchOnboardingFamiliesReportRoute.loader}>
                <Route path=":campus_filter" element={<WorkbenchOnboardingFamiliesReportFilterRoute.Element />} loader={WorkbenchOnboardingFamiliesReportFilterRoute.loader} action={WorkbenchOnboardingFamiliesReportFilterRoute.action}>
                  <Route path="add-families-to-group" element={<WorkbenchOnboardingAddFamiliesToGroupRoute />} action={workbenchOnboardingAddFamiliesToGroupAction} loader={workbenchOnboardingAddFamiliesToGroupLoader} />
                </Route>
              </Route>
              <Route path="groups" element={<OnboardingGroupsRoute.Element />} loader={OnboardingGroupsRoute.loader}>
                <Route path=":campusId" element={<OnboardingGroupsCampusId.Element />} loader={OnboardingGroupsCampusId.loader} action={OnboardingGroupsCampusId.action}>
                  <Route path="new" element={<WorkbenchOnboardingGroupsNewRoute />} loader={workbenchOnboardingGroupsNewLoader} action={workbenchOnboardingGroupsNewAction} />
                  <Route path="edit/:onboarding_group_id" element={<WorkbenchOnboardingGroups$OnboardingGroupIdRoute />} loader={workbenchOnboardingGroups$OnboardingGroupIdLoader} action={workbenchOnboardingGroups$OnboardingGroupIdAction} />
                  <Route path="duplicated-group-modal/:onboarding_group_id" element={<WorkbenchOnboardingGroupDuplicatedGroupModal$onboardingIdRoute />} loader={workbenchOnboardingGroupDuplicatedGroupModal$onboardingIdLoader} />
                </Route>
              </Route>
            </Route>
            <Route path="onboarding/:onboarding_group_id/content" element={<WorkbenchOnboardingCampus$OnboardingGroupIdContentRoute />} loader={workbenchOnboardingCampus$OnboardingGroupIdContentLoader}>
              <Route path="checklist">
                <Route path="new" element={<WorkbenchOnboardingChecklistNewRoute />} loader={workbenchOnboardingChecklistNewRouteLoader} action={workbenchOnboardingChecklistNewRouteAction} />
                <Route path=":checklist_id" element={<OnboardingChecklistIdRoute.Element />} loader={OnboardingChecklistIdRoute.loader} action={OnboardingChecklistIdRoute.action}>
                  <Route path=":user_view_type" element={<OnboardingUserTypeRoute.Element />} loader={OnboardingUserTypeRoute.loader} action={OnboardingUserTypeRoute.action}>
                    <Route path="new" element={<WorkbenchOnboardingChecklistNewRoute />} loader={workbenchOnboardingChecklistNewRouteLoader} action={workbenchOnboardingChecklistNewRouteAction} />
                    <Route path="edit" element={<WorkbenchOnboardingChecklistEditRoute />} loader={workbenchOnboardingChecklistEditLoader} action={workbenchOnboardingChecklistEditAction} />
                    <Route path="todo/:todo_id" element={<Onboarding$TodoIdRoute.Element />} loader={Onboarding$TodoIdRoute.loader} action={Onboarding$TodoIdRoute.action} />
                  </Route>
                </Route>
              </Route>
            </Route>
            <Route path="mailchimp" element={<WorkbenchMailchimpRoute />} />
            <Route path="external-students" lazy={() => import('@features/workbench.external-students')}>
              <Route path="import-students" lazy={() => import('@features/workbench.external-students.import-students')} />
            </Route>
            <Route path="external-students/:id" lazy={() => import('@features/workbench.external-students_.$id')}>
              <Route path="overview" lazy={() => import('@features/workbench.external-students_.$id.overview')} />
            </Route>
            <Route path="external-students/:id?/edit" lazy={() => import('@features/workbench.external-students_.($id)_.edit')} />
            <Route path="admin-actions" element={<WorkbenchAdminRoute />} />
            <Route path="canny" element={<WorkbenchCannyRoute />}>
              <Route path="sync-users" element={<WorkbenchCannySyncUsersRoute />} action={workbenchCannySyncUsersAction} />
            </Route>
            <Route path="google" lazy={() => import('@features/workbench.google')}>
              <Route path="sync-groups" lazy={() => import('@features/workbench.google.sync-groups')} />
              <Route path="force-holidays" lazy={() => import('@features/workbench.google.force-holidays')} />
              <Route path="house-events" lazy={() => import('@features/workbench.google.house-events')} />
              <Route path="students-calendar-cache" lazy={() => import('@features/workbench.google.students-calendar-cache')} />
            </Route>
            <Route path="report/progress" element={<ReleaseProgressReport />} loader={releaseProgressReportLoader} />
            <Route path="feature-flags" element={<FeatureFlags />}>
              <Route path=":feature_flag_id/sora-labs-settings" element={<FeatureFlagSettingsLabsRoute.Element />} loader={FeatureFlagSettingsLabsRoute.loader} action={FeatureFlagSettingsLabsRoute.action} />
            </Route>
            <Route path="permissions" element={<Permissions />} />
            <Route path="math-placement" element={<WorkbenchMathPlacementRoute.Element />}>
              <Route index loader={WorkbenchMathPlacementIndexRoute.loader} />
              <Route path="timeline" element={<WorkbenchMathPlacementTimelineRoute.Element />} loader={WorkbenchMathPlacementTimelineRoute.loader} />
              <Route path="students" element={<WorkbenchMathPlacementStudentsRoute.Element />} loader={WorkbenchMathPlacementStudentsRoute.loader} action={WorkbenchMathPlacementStudentsRoute.action} />
            </Route>
            <Route path="enrolments" element={<EnrolmentsRoute />} loader={enrolmentsLoader} action={enrolmentsAction} />
            <Route path="notifications" lazy={() => import('@features/workbench.notifications')} />
            <Route path="admissions" element={<Admissions />} loader={admissionsLoader} action={admissionsAction} />
            <Route path="config-admissions" element={<AdmissionsConfig />} >
              <Route path="copy" element={<AdmissionsCopyConfig />} loader={admissionsCopyConfigLoader} action={admissionsCopyConfigAction} />
              <Route path="start-dates" element={<AdmissionsStartDatesConfig />} loader={admissionsStartDatesConfigLoader} action={admissionsStartDatesConfigAction} />
            </Route>
            <Route path="special-event-logo" lazy={() => import('@routes/admin/workbench/special-event-logo')} >
              <Route path=":schoolStage/edit" lazy={() => import('@routes/admin/workbench/special-event-logo.$schoolStage.edit')} />
            </Route>

            <Route path="zoom" element={<WorkbenchZoomRoute.Element />} loader={WorkbenchZoomRoute.loader} action={WorkbenchZoomRoute.action} />
          </Route>

        </Route>

        <Route path="/" element={<IndexNoAuthRoute />} loader={indexNoAuthLoader}>
          <Route path="signin" element={<SignInRoute />} loader={signInLoader} />
          <Route path="password/new" element={<NewPasswordRoute.Element />} loader={NewPasswordRoute.loader} action={NewPasswordRoute.action} />
        </Route>

        <Route path="*" element={<NotFoundRoute />} />
      </Route >
    ),
  )
  return (
    <ErrorBoundary>
      <Toaster />
      <PageTitleProvider>
        <ConfirmModalProvider>
          <CustomSWRConfig>
            <TooltipBase.Provider>
              <RouterProvider router={router} />
            </TooltipBase.Provider>
          </CustomSWRConfig>
        </ConfirmModalProvider>
      </PageTitleProvider>
    </ErrorBoundary>
  )
}

function NotFoundRoute() {
  return <ErrorComponent type="PAGE_NOT_FOUND" />
}

const CustomSWRConfig = ({ children }) => {
  const fetcher = async (url) => {
    const res = await axios.get(url).catch((error) => error.response)
    return res.data
  }
  const onError = (err) => {
    captureException(err)
  }

  return (
    <SWRConfig value={{ fetcher, onError }}>
      {children}
    </SWRConfig>
  )
}

CustomSWRConfig.propTypes = {
  children: PropTypes.any,
}

export default Sentry.withProfiler(App)
