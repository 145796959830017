import React from 'react'
import { ActionFunctionArgs, LoaderFunctionArgs, useLoaderData, Outlet, redirect } from 'react-router'
import axios from 'axios'
import { Avatar, Button, cn, Icon, Typography, unstable_Tooltip as Tooltip, EmptyState } from '@design-system'
import SoraLink from '@components/link'
import serializeFormData from '@utils/serializeFormData'
import { BucketType, HouseReportLoaderData } from './types'
import { PopoverDetails } from './popover-details'
import { NavActions } from './nav-actions'
import Mascot from './mascot.svg'

async function loader({ request, params }: LoaderFunctionArgs) {
  const { houseId, reportId } = params
  const searchParams = new URL(request.url).searchParams
  const { data } = await axios.get(`/backoffice/houses/${houseId}/overview/${reportId}?${searchParams.toString()}`)
  return data
}

async function action({ request, params }: ActionFunctionArgs) {
  const { houseId, reportId } = params
  const formData = await request.formData()
  const { data } = await axios.post(`/backoffice/houses/${houseId}/overview/${reportId}`, serializeFormData(formData))
  return data
}

function Element() {
  const data = useLoaderData() as HouseReportLoaderData
  const isEmpty = Object.keys(data).length === 0
  const bucketOrder = Object.values(BucketType)
  const buckets = Object.keys(data).sort((a, b) => bucketOrder.indexOf(BucketType[a]) - bucketOrder.indexOf(BucketType[b]))
  const [isCompletedOpen, setIsCompletedOpen] = React.useState(false)
  const toggleCompleted = () => setIsCompletedOpen(!isCompletedOpen)
  return (
    <div className="space-y-8">
      {isEmpty && (
        <EmptyState>
          <EmptyState.Illustration>
            <img src={Mascot} alt="Nothing to report" />
          </EmptyState.Illustration>
          <EmptyState.Title>Nothing to report</EmptyState.Title>
          <EmptyState.Description>There is nothing to report this week</EmptyState.Description>
        </EmptyState>
      )}
      {buckets.map((bucket) => (
        <section key={bucket}>
          <div className="flex justify-between">
            <Typography variant="subheadline" weight="bold">{BucketType[bucket]} {bucket === 'completed' && `(${data[bucket].length})`}</Typography>
            {bucket === 'completed' && (
              <Tooltip content={isCompletedOpen ? 'Collapse' : 'Expand'}>
                <Button onClick={toggleCompleted} variant="ghost" size="sm">
                  <Icon size="sm" name={isCompletedOpen ? 'chevron-up' : 'chevron-down'} />
                </Button>
              </Tooltip>
            )}
          </div>
          {(isCompletedOpen && bucket === 'completed' || bucket !== 'completed') && (
            <ul className="my-4">
              {data[bucket].map(({ id, studentId, studentName, studentProfileImageUrl, title, recommendation, approachType, doneAt, dismissedAt }) => (
                <li key={id} className="flex gap-4 py-6 border-t items-start border-gray-30 dark:border-gray-90 group">
                  <SoraLink to={`/students/${studentId}`}>
                    <Avatar size="xl">
                      <Avatar.Image src={studentProfileImageUrl} alt={studentName} />
                      <Avatar.Fallback>{studentName}</Avatar.Fallback>
                    </Avatar>
                  </SoraLink>
                  <div className="flex flex-col md:flex-row md:items-center gap-4 grow">
                    <div className={cn("flex flex-col grow", bucket === 'completed' && "line-through")}>
                      <Typography variant="body" weight="bold" className="flex items-center gap-1">
                        {studentName} {title}
                        <PopoverDetails houseReportItemId={id} />
                      </Typography>
                      <Typography variant="callout">
                        {`Recommendation: `}
                        {recommendation === "CELEBRATE" && (<>Send student a message of encouragement</>)}
                        {recommendation === "MESSAGE_STUDENT" && (<>Message student</>)}
                        {recommendation === "MESSAGE_STUDENT_AND_GUARDIANS" && (<>Message student and guardians</>)}
                        {["MESSAGE_STUDENT", "MESSAGE_STUDENT_AND_GUARDIANS"].includes(recommendation) && (<> or <SoraLink className="font-bold text-accent" to={`/students/${studentId}`}>gather more info</SoraLink></>)}
                      </Typography>
                    </div>
                    <NavActions id={id} doneAt={doneAt} dismissedAt={dismissedAt} />
                  </div>
                </li>
              ))}
            </ul>
          )}
        </section>
      ))}
      <Outlet />
    </div>
  )
}


export const HouseOverviewReportRoute = {
  loader,
  action,
  Element,
}


