import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Button, Typography, useFetcher } from '@design-system'
import { UnitList } from './unit-list'
import { TemplateManagement } from './template-management'

export const CyclePlanInput = ({ position, cycleId, templates = [], activeTemplates = [] }) => {
  const fetcher = useFetcher()
  const [isEditing, setIsEditing] = useState(false)
  const initialTemplatesRef = useRef(templates)
  const [currentTemplateIds, setCurrentTemplateIds] = useState(templates.map(t => t.id))

  const isLoading = fetcher.state !== 'idle' && fetcher.formData?.get('cycleId') === String(cycleId)

  useEffect(() => {
    if (fetcher.state === 'idle' && fetcher.data) {
      initialTemplatesRef.current = templates
      setCurrentTemplateIds(templates.map(t => t.id))
    }
  }, [templates, fetcher.state, fetcher.data])

  useEffect(() => {
    if (fetcher.state === 'idle' && fetcher.data) {
      setIsEditing(false)
    }
  }, [fetcher.state, fetcher.data])

  const handleTemplateChange = (templateIds) => {
    setCurrentTemplateIds(templateIds)
  }

  const hasChanges = () => {
    const currentIds = currentTemplateIds.filter(id => id !== null)
    const initialIds = initialTemplatesRef.current.map(t => t.id)
    return JSON.stringify(currentIds) !== JSON.stringify(initialIds)
  }

  if (isEditing) {
    return (
      <fetcher.Form method="POST" className='flex flex-col gap-4'>
        <input type="hidden" name="_action" value="update_cycle_plan" />
        <input type="hidden" name="cycleId" value={cycleId} />
        <div className="flex flex-row gap-2 justify-between items-center">
          <Typography variant="heading-6" weight="bold">Cycle {position}</Typography>
          <div className='flex flex-row gap-2'>
            <Button type="button" variant="secondary" size='sm' onClick={() => setIsEditing(false)}>
              Cancel
            </Button>
            <Button 
              type='submit' 
              size='sm' 
              disabled={isLoading || !hasChanges()} 
              loading={isLoading}
            >
              Save
            </Button>
          </div>
        </div>
        <TemplateManagement 
          cycleId={cycleId} 
          templates={templates} 
          activeTemplates={activeTemplates}
          onChange={handleTemplateChange}
        />
      </fetcher.Form>
    )
  }

  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-row gap-2 justify-between items-center">
        <Typography variant="heading-6" weight="bold">Cycle {position}</Typography>
        <Button variant="secondary" size='sm' onClick={() => setIsEditing(true)}>	
          Edit
        </Button>
      </div>
      {templates.map((template, tmpIdx) => (
        <React.Fragment key={`template_${tmpIdx}`}>
          <div className="flex flex-row items-center pt-4 border-t border-alpha/10">
            <Typography variant="body" weight="bold" className='w-1/4'>Template {tmpIdx+1}</Typography>
            <Typography variant="body">{template.title}</Typography>
          </div>
          <div className="flex flex-row items-center pt-4 border-t border-alpha/10">
            <Typography variant="body" weight="bold" className='w-1/4'>Template {tmpIdx+1} Units</Typography>
            <UnitList units={template.units} />
          </div>
        </React.Fragment>
      ))}
    </div>
  )
}
CyclePlanInput.propTypes = {
  position: PropTypes.number,
  cycleId: PropTypes.number,
  templates: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    units: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      title: PropTypes.string,
    })),
    planId: PropTypes.number,
  })),
  activeTemplates: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    items: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      title: PropTypes.string,
      units: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number,
        title: PropTypes.string,
      })),
    })),
  })),
}
