import React from 'react'
import PropTypes from 'prop-types'
import { Typography } from '@design-system'
export const UnitList = ({ units = [], disabled = false }) => {

  return (
    <div className='flex flex-col'>
      {units.length === 0 && <span className='text-alpha/40'>No units required </span>}
      {units.length > 0 && 
        <ul>
          {units.map(unit => (
            <li key={unit.id}>
              <Typography variant='body' className={disabled ? 'text-alpha/40' : ''}>[{unit.topic_title}] {unit.title}</Typography>
            </li>
          ))}
        </ul>
      }
    </div>
  )
}
UnitList.propTypes = {
  units: PropTypes.array,
  disabled: PropTypes.bool,
}
