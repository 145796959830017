import React from 'react'
import axios from "axios"
import { Link, useSearchParams, useLoaderData } from 'react-router'
import { Icon, Button, Typography, EmptyState, useFetcher } from '@design-system'
import EmptyStateIllustration from './illustration.svg'
import serializeFormData from '@utils/serializeFormData'

async function loader({ params }) {
  const result = await axios.get(`/backoffice/facilitate/${params.type}/${params.experienceId}/attendance/default/${params.sessionId}/zoom-report`)
  return result.data
}

async function action({ params, request }) {
  try {
    const formData = await request.formData()
    const serializedFormData = serializeFormData(formData)
    const response = await axios.post(`/backoffice/facilitate/${params.type}/${params.experienceId}/attendance/default/${params.sessionId}/zoom-report`, serializedFormData)
    return response.data
  } catch (error) {
    const data = error.response?.data
    return data || {
      toast: {
        message: 'Something went wrong. Please try again later.',
        appearance: 'error',
      }
    }
  }
}

function Element() {
  const loaderData = useLoaderData()
  const [searchParams] = useSearchParams()
  const fetcher = useFetcher()
  return <div className="fixed top-0 right-0 bottom-0 min-w-fit-content z-100 bg-white dark:bg-gray-100 px-10 border border-r dark:border-gray-90 overflow-scroll" style={{ marginTop: '0px' }}>
    <div className="flex flex-row items-center gap-4 justify-between pb-8 py-10 sticky top-0 bg-white dark:bg-gray-100">
      <Typography variant="heading-4" weight="bold">
        Zoom Report
      </Typography>
      <Button asChild variant="outlined" color="soft" size="sm">
        <Link to={'..?' + searchParams.toString()}>
          <Icon name="cross" size="sm" />
        </Link>
      </Button>
    </div>
    <table className="table-auto">
      <thead className="sticky top-24 bg-white dark:bg-gray-100">
        <tr>
          <th>
            <Typography variant="heading-6" className="pb-4 text-left">
              Participant
            </Typography>
          </th>
          <th>
            <Typography variant="heading-6" className="pl-6 pb-4 text-right">
              Joined At
            </Typography>
          </th>
          <th>
            <Typography variant="heading-6" className="pl-6 pb-4 text-right">
              Total Time
            </Typography>
          </th>
        </tr>
      </thead>
      <tbody className="h-full ">
        {loaderData?.map((participant) => (
          <tr key={participant.name} className="hover:bg-gray-5 dark:bg-gray-98">
            <td className="flex items-center">
              <Typography variant="body" className="py-2">
                {participant.name}
              </Typography>
              {participant.isVerified && (
                <Icon name="shield-check-filled" size='xs' className="text-success-50 ml-2" />
              )}
            </td>
            <td>
              <Typography variant="body" className="text-right py-2">
                {new Date(participant.joinedAt).toLocaleTimeString()}
              </Typography>
            </td>
            <td>
              <Typography variant="body" className="text-right py-2">
                {participant.time}
              </Typography>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
    {loaderData?.length === 0 && (
      <EmptyState>
        <EmptyState.Illustration>
          <img src={EmptyStateIllustration} alt="" />
        </EmptyState.Illustration>
        <EmptyState.Title>No Participants Found</EmptyState.Title>
        <EmptyState.Description>
          There are no participants in this session.
        </EmptyState.Description>
      </EmptyState>
    )}
    <fetcher.Form method="POST" className="flex flex-col items-start space-y-4 my-12">
      <Button type="submit" name="_action" value="conciliate" loading={fetcher.state !== 'idle'}>Update statuses</Button>
      <Typography variant="body" className="text-wrap w-full">
        You can apply this report data to update this <br />attendance students' statuses automatically:
      </Typography>
      <Typography variant="body">
        <ul className="list-disc list-inside">
          <li>Present when the student joined</li>
          <li>Late when joined 3 minutes after the start of the meeting</li>
          <li>Insufficient when stayed for less than half of the meeting</li>
        </ul>
      </Typography>
      <Typography variant="body" className="text-wrap w-full italic">
        This action will overwrite any manual changes in the statuses
      </Typography>
    </fetcher.Form>
  </div>
}

export const FacilitateExperienceAttendanceDefaultSessionZoomReportRoute = {
  loader,
  action,
  Element,
}
