import React from 'react'
import axios from 'axios'
import { useLoaderData, Outlet, useSearchParams } from 'react-router'
import { Button, Card, Icon, Typography, Modal, EmptyState } from '@design-system'
import useNavigateWithCycle from '@hooks/useNavigateWithCycle'
import serializeFormData from "@utils/serializeFormData"
import { RemovePathway } from './remove-pathway'
import { NewPathway } from './new-pathway'
import { SchoolYearSelector } from './school-year-selector'
import EmptyStateIllustration from './empty-state.svg'
import SoraLink from '@components/link'

const loader = async ({ params, request }) => {
  const searchParams = new URL(request.url).searchParams
  const action = searchParams.get('_action') || 'default'
  const cycleId = searchParams.get('cycle_id')
  const schoolYear = searchParams.get('schoolYear')
  
  const result = await axios.get('/backoffice/workbench/pathways/management', { params: { action, cycleId, schoolYear } })
  return result?.data
}

const action = async ({ params, request }) => {
  const searchParams = new URL(request.url).searchParams
  const schoolYear = searchParams.get('schoolYear')

  const formData = await request.formData()
  const action = formData.get('_action')
  const serializedFormData = serializeFormData(formData)

  try {
    const result = await axios.post(`/backoffice/workbench/pathways/management`, { ...serializedFormData, action, schoolYear })
    return result?.data
  } catch (error) {
    return {
      toast: { message: 'There was an error running this action. If the problem persists, contact our tech support team.', appearance: 'error' }
    }
  }
}

const Element = () => {
  const [searchParams] = useSearchParams()
  const navigate = useNavigateWithCycle()
  const { pathways, availableSchoolYears } = useLoaderData()

  const schoolYears = availableSchoolYears.map(year => ({
    value: year.school_year,
    label: year.title,
    selected: searchParams.get('schoolYear') ? Number(searchParams.get('schoolYear')) === year.school_year : year.selected,
  }))

  const categoryView = (title) => {
    switch (title.toLowerCase()) {
      case 'stem':
        return (<span className='rounded py-1 px-2 bg-green-5 dark:bg-green-40 font-bold text-xs whitespace-nowrap'>Stem</span>)
      case 'humanities':
        return (<span className='rounded py-1 px-2 bg-turquoise-5 dark:bg-turquoise-40 font-bold text-xs whitespace-nowrap'>Humanities</span>)
      case 'math_learning_goal':
        return (<span className='rounded py-1 px-2 bg-purple-5 dark:bg-purple-40 font-bold text-xs whitespace-nowrap'>Math</span>)
      case 'electives':
        return (<span className='rounded py-1 px-2 bg-orange-5 dark:bg-orange-40 font-bold text-xs whitespace-nowrap'>Electives</span>)
    }
  }

  const getOrdinalGrade = (grade: number): string => {
    const suffix = ['th', 'st', 'nd', 'rd']
    const v = grade % 100
    return `${grade}${suffix[(v - 20) % 10] || suffix[v] || suffix[0]}`
  }

  return (
    <Modal.Provider>
      <Card className='mt-4'>
        <Card.Content className='flex flex-row justify-around p-0'>
          <div className='flex flex-col w-full'>
            <div className='flex flex-row justify-between items-center p-4 gap-4 border-b'>
              <SchoolYearSelector schoolYears={schoolYears} defaultValue={schoolYears.find(year => year.selected)?.value} />
              <NewPathway />
            </div>
            {(!pathways || pathways.length === 0) &&
              <EmptyState>
                <EmptyState.Title>No pathways</EmptyState.Title>
                <EmptyState.Description>This school year doesn't have any pathways added yet.</EmptyState.Description>
                <EmptyState.Illustration>
                  <img src={EmptyStateIllustration} alt="Hand drawn illustration of a puppet pointing to a rotatory globe" />
                </EmptyState.Illustration>
              </EmptyState>
            }
            {pathways?.length > 0 &&
              <table className='w-full'>
                <thead>
                  <tr>
                    <th className='p-3 text-left'>
                      <Typography variant='callout' weight='bold'>Title</Typography>
                    </th>
                    <th className='p-3 text-left whitespace-nowrap'>
                      <Typography variant='callout' weight='bold'>Type</Typography>
                    </th>
                    <th className='p-3 text-left whitespace-nowrap'>
                      <Typography variant='callout' weight='bold'>Default Grade</Typography>
                    </th>
                    <th className='p-3 text-left whitespace-nowrap'>
                      <Typography variant='callout' weight='bold'>Students Assigned</Typography>
                    </th>
                    <th className='w-20'></th>
                  </tr>
                </thead>
                <tbody>
                  {pathways.map(pathway => (
                    <tr key={pathway.id} className='hover:bg-alpha/5 border-t'>
                      <td className='p-3 cursor-pointer hover:underline' onClick={() => navigate(`./${pathway.id}`, { queryCopy: ['schoolYear'] })}>
                        {pathway.title}
                      </td>
                      <td className='p-3'>{categoryView(pathway.type)}</td>
                      <td className='p-3'>{getOrdinalGrade(pathway.grade_default)} grade</td>
                      <td className='p-3'>{pathway.students_amount}</td>
                      <td className='flex flex-row gap-2 justify-end p-3'>
                        <RemovePathway id={pathway.id} />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            }
          </div>
        </Card.Content>
      </Card>
      <Outlet />
    </Modal.Provider>
  )
}

export const WorkbenchPathwaysManagementIndexRoute = {
  loader,
  action,
  Element
} 