import React from 'react'
import { Combobox, useFetcher } from '@design-system'
import { useSearchParams } from 'react-router'

interface SchoolYear {
  value: number
  label: string
  selected?: boolean
}

interface SchoolYearSelectorProps {
  schoolYears?: SchoolYear[]
  defaultValue?: number
}

export const SchoolYearSelector: React.FC<SchoolYearSelectorProps> = ({ 
  schoolYears = [], 
  defaultValue 
}) => {
  const fetcher = useFetcher()
  const [_, setSearchParams] = useSearchParams()

  const isLoading = fetcher.state !== 'idle'

  const handleFormChange = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    const form = event.currentTarget
    const urlParams = new URLSearchParams(document.location.search)
    const currentParams = Object.fromEntries(urlParams)
    const formData = new FormData(form)
    const schoolYear = formData.get('schoolYear')
    if (schoolYear) {
      setSearchParams({ ...currentParams, schoolYear: schoolYear.toString() })
    }
    fetcher.submit(form)
  }

  const handleComboboxChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const urlParams = new URLSearchParams(document.location.search)
    const currentParams = Object.fromEntries(urlParams)
    setSearchParams({ ...currentParams, schoolYear: event.target.value })
    fetcher.submit(event.target.form!)
  }

  return (
    <div className='flex flex-row items-center'>
      <fetcher.Form method="GET" onSubmit={handleFormChange} className='flex flex-col'>
        <Combobox 
          name='schoolYear' 
          defaultValue={String(defaultValue) || String(schoolYears.slice(-1)[0]?.value)} 
          loading={isLoading}
          onChange={handleComboboxChange}
          prefix='School Year'
          variant='borderless'
        >
          {schoolYears.map(schoolYear => (
            <Combobox.Item key={schoolYear.value} value={String(schoolYear.value)}>
              {schoolYear.label}
            </Combobox.Item>
          ))}
        </Combobox>
      </fetcher.Form>
    </div>
  )
} 