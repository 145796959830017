import React from 'react'
import { Link, useLoaderData, useNavigate, useParams } from 'react-router'
import formatDateForOnboarding from '@components/onboarding/utils/formatDateForOnboarding'
import axios from 'axios'
import { Button, Dialog, Typography } from '@design-system'

export async function loader({ params }) {
  const res = await axios.get(`/pages/admin/workbench/onboarding/groups/${params.campus_filter}/duplicated-group-modal/${params.onboarding_group_id}`)
  return res.data
}

interface LoaderData {
  title: string,
  startDate: string
}

export default function WorkbenchOnboardingGroupDuplicatedGroupModal$onboardingIdRoute() {
  const params = useParams()
  const navigate = useNavigate()
  const loaderData = useLoaderData() as LoaderData
  return (
    <Dialog
      open
      onClose={() => navigate('..')}
    >
      <Dialog.Content>
        <Dialog.Header>
          <Dialog.Title variant="heading-6" weight="bold">Duplicated Group</Dialog.Title>
        </Dialog.Header>
        <Dialog.Body>
          <Typography>You already have an onboarding for {loaderData.title} starting on {formatDateForOnboarding(loaderData.startDate)}.</Typography>
        </Dialog.Body>
        <Dialog.Footer>
          <Button asChild variant="outlined">
            <Link to="..">Cancel</Link>
          </Button>
          <Button asChild>
            <Link to={`../../../${params.onboarding_group_id}/content`}>
              View Existing Onboarding Group
            </Link>
          </Button>
        </Dialog.Footer>
      </Dialog.Content>
    </Dialog>
  )
}