import React from 'react'
import axios from 'axios'
import { useLoaderData, useOutletContext } from 'react-router'
import { Button, Card, Icon, Typography } from '@design-system'
import { CyclePlanInput } from './cycle-plan-input'
import serializeFormData from '@utils/serializeFormData'
import useNavigateWithCycle from '@hooks/useNavigateWithCycle'
import { BasicDetails } from './basic-details'

const loader = async ({ params, request }) => {
  const { pathwayId } = params

  const searchParams = new URL(request.url).searchParams
  const action = searchParams.get('_action') || 'default'
  const planId = searchParams.get('planId')

  const result = await axios.get(`/backoffice/workbench/pathways/management/${pathwayId}`, { params: { action, planId } })
  return result?.data
}

const action = async ({ params, request }) => {
  const { pathwayId } = params
  const formData = await request.formData()
  const action = formData.get('_action')
  const serializedFormData = serializeFormData(formData)

  try {
    const result = await axios.post(`/backoffice/workbench/pathways/management/${pathwayId}`, { ...serializedFormData, action })
    return  result?.data
  } catch (error) {
    return {
      toast: { message: 'There was an error running this action. If the problem persists, contact our tech support team.', appearance: 'error' }
    }
  }
}

const Element = () => {
  const navigate = useNavigateWithCycle()
  const { pathway, plan, types, activeTemplates } = useLoaderData()
  const { setShowHeader } = useOutletContext()

  React.useEffect(() => {
    setShowHeader(false)
    return () => {
      setShowHeader(true)
    }
  }, [setShowHeader])

  const handleDone = (e) => {
    e.preventDefault()
    navigate('../', { queryCopy: ['schoolYear'] })
  }

  return (
    <div className='flex flex-col gap-8'>
      <div className='flex flex-row gap-2 items-center '>
        <Button variant='secondary' size='sm' onClick={handleDone} >
          <Icon name='arrow-left' />
        </Button>
        <Typography variant='heading-3' weight='bold'>Pathways</Typography>
      </div>
      <div className='flex flex-col p-2 gap-4'>
        <Card>
          <Card.Content>
            <BasicDetails pathway={pathway} types={types} />
          </Card.Content>
        </Card>
        {plan.map((item, index) => (
          <Card key={`cycle_plan_${index}_${item.cycleId}`} >
            <Card.Content>
              <CyclePlanInput
                index={index} 
                position={item.cyclePosition} 
                cycleId={item.cycleId}
                templates={item.templates}
                activeTemplates={activeTemplates}
              />
            </Card.Content>
          </Card>
        ))}
      </div>
    </div>
  )
}

export const WorkbenchPathwaysManagementPathwayIdRoute  = {
  loader,
  action,
  Element,
}
