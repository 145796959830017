import React, { useEffect, useRef, useState } from 'react'
import { toast } from 'sonner'
import { useLoaderData, useRevalidator, useSearchParams } from 'react-router'
import axios from 'axios'
import _debounce from 'lodash/debounce'
import Select from '@components/forms/select'
import { useThisPageTitle } from '@hooks/usePageTitle'
import { Switch } from '@headlessui/react'
import { Button } from '@designsystem'
import PropTypes from 'prop-types'
import Checkbox from '@components/forms/checkbox'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import SoraLink from '@components/link'
import capitalize from '@utils/capitalize'
import { faSquare, faCheckSquare } from '@fortawesome/free-regular-svg-icons'

export const loader = async ({ request: { url } }) => {
  const { search } = new URL(url)
  const [{ data }, { data: { result: cohorts } }] = await Promise.all([
    axios.get(`/pages/admin/workbench/report/progress/students${search}`),
    axios.get('/cohorts')
  ])
  return {
    ...data,
    cohorts: cohorts.filter(c => c.cohort_id !== 4 && c.cohort_id !== 5)
  }
}

const ReleaseProgressReport = () => {
  useThisPageTitle('Report Releasement')
  const { studentsProgresses, cohorts, cycleId } = useLoaderData()
  const { revalidate: mutate } = useRevalidator()
  const [searchParams, setSearchParams] = useSearchParams()

  const [allStudentsChecked, checkAllStudents] = useState(false)
  const [publish, setPublish] = useState(true)
  const [publishing, setPublishing] = useState(false)
  const [studentIds, setStudentIds] = useState([])

  const cohortsListOpts = [{ label: 'Any', value: '' }].concat(cohorts.map(c => ({ label: c.title, value: c.cohort_id })))
  const statusListOpts = [
    { label: 'Any', value: '' },
    { label: 'True', value: 'true' },
    { label: 'False ', value: 'false' },
  ]

  const sync = (studentIds = [], reports = {}) => {
    return axios.post('/pages/admin/workbench/report/progress/sync?', { cycle_id: cycleId, student_ids: studentIds, reports })
  }

  const release = (report_type) => {
    return axios.post(`/pages/admin/workbench/report/progress/assessment/release`, { cycle_id: cycleId, report_type })
  }

  const filterStudentCohorts = (cohort_id) => {
    setStudentIds([])
    checkAllStudents(false)
    setSearchParams(prev => ({ ...Object.fromEntries(prev), cohort_id }))
  }

  const filterStatus = (status_type) => ({ value: status_value }) => {
    setStudentIds([])
    checkAllStudents(false)
    setSearchParams(prev => ({ ...Object.fromEntries(prev), [status_type]: status_value }))
  }

  const searchStudents = _debounce(({ target: { value: student = '' } }) => {
    setStudentIds([])
    checkAllStudents(false)
    setSearchParams(prev => ({ ...Object.fromEntries(prev), student }))
  }, 300)

  const toggleAll = () => {
    setStudentIds(
      !allStudentsChecked
        ? studentsProgresses.reduce((acc, curr) => [curr.student_id, ...acc], [])
        : []
    )
    checkAllStudents(!allStudentsChecked)
  }

  const handleMidCycle = ({ publish, ids }) => async () => {
    setPublishing(true)
    try {
      const res = await sync(ids, { mid_cycle: publish })
      if (res.status >= 200 && res.status < 300) {
        await mutate()
        toast.success(`${publish ? 'Published' : 'Un-published'} Mid-cycle report from all selected students`)
      } else {
        toast.error(`Failed to ${publish ? 'publish' : 'un-publish'}: ${res.data?.error || 'Unknown error'}`)
      }
    } catch (error) {
      const errorMessage = error.response?.data?.error || error.message || 'Please try again later'
      toast.error(`Unable to ${publish ? 'publish' : 'un-publish'}: ${errorMessage}`)
    } finally {
      setPublishing(false)
      checkAllStudents(false)
      setStudentIds([])
    }
  }

  const handleEndCycle = ({ publish, ids }) => async () => {
    setPublishing(true)
    try {
      const res = await sync(ids, { end_cycle: publish })
      if (res.status >= 200 && res.status < 300) {
        await mutate()
        toast.success(`${publish ? 'Published' : 'Un-published'} End-cycle report from all selected students`)
      } else {
        toast.error(`Failed to ${publish ? 'publish' : 'un-publish'}: ${res.data?.error || 'Unknown error'}`)
      }
    } catch (error) {
      const errorMessage = error.response?.data?.error || error.message || 'Please try again later'
      toast.error(`Unable to ${publish ? 'publish' : 'un-publish'}: ${errorMessage}`)
    } finally {
      setPublishing(false)
      checkAllStudents(false)
      setStudentIds([])
    }
  }

  const releaseAssessment = (type) => async () => {
    try {
      const res = await release(type)
      if (res.status >= 200 && res.status < 300) {
        toast.success('Released to review progress reports')
      } else {
        toast.error('Unable to release the assessment, please try again later.')
      }
    } catch (error) {
      toast.error('Unable to release the assessment, please try again later.')
    }
  }

  const updateStudent = (studentId, add) => {
    const list = add ? [studentId, ...studentIds] : studentIds.filter(x => Number(x) !== Number(studentId))
    setStudentIds(list)
    if (list.length === 0) checkAllStudents(false)
    if (list.length === studentsProgresses.length) checkAllStudents(true)
  }

  return (
    <section className="px-4 sm:px-6 lg:px-4 xl:px-6 pt-4 pb-4 sm:pb-6 lg:pb-4 xl:pb-6 space-y-4 rounded">
      <div className="flex justify-between">
        <div className="flex flex-col space-y-3 xl:space-y-0 xl:flex-row xl:space-x-3 mt-auto">
          <Button onClick={toggleAll} variant="outlined" size="sm" startIcon={allStudentsChecked ? faCheckSquare : faSquare}>
            {!allStudentsChecked ? 'Check All' : 'Uncheck All'} ({studentIds.length}/{studentsProgresses.length})
          </Button>
        </div>
        <div className="flex flex-col space-y-3 xl:space-y-0 xl:flex-row xl:space-x-3 mt-auto">
          <fieldset className="flex border shadow p-2 ml-4 justify-around">
            <legend className="font-bold px-2 flex justify-between">Release Assessment</legend>
            {!publishing && <Button onClick={releaseAssessment('mid-cycle')} size="sm">Mid-Cycle</Button>}
            {publishing && <div className="py-1 text-center animate-pulse">Processing...</div>}
          </fieldset>
          <fieldset className="flex flex-row border shadow p-2 ml-4 w-64 justify-around">
            <legend className="font-bold px-2 flex flex-row justify-between">
              <Switch
                checked={publish} onChange={setPublish}
                className={`${publish ? 'bg-blue-50' : 'bg-gray-10'} relative inline-flex items-center h-6 rounded-full w-12 transition-colors ease-in-out duration-200`}
              >
                <span className="sr-only">Publish/Un-publish</span>
                <span className={`${publish ? 'translate-x-6' : 'translate-x-1'} inline-block w-4 h-4 bg-white rounded-full transform ring-0 transition ease-in-out duration-200`} />
              </Switch>
              <span className="ml-2">{publish ? 'Publish' : 'Un-publish'}</span>
            </legend>
            {!publishing &&
              <div className="flex space-x-2">
                <Button onClick={handleMidCycle({ publish, ids: studentIds })} size="sm" disabled={!studentIds.length}>
                  Mid-Cycle
                </Button>
                <Button onClick={handleEndCycle({ publish, ids: studentIds })} size="sm" disabled={!studentIds.length}>
                  End-Cycle
                </Button>
              </div>
            }
            {publishing &&
              <div className="py-1 text-center animate-pulse">
                Processing...
              </div>
            }
          </fieldset>
        </div>
      </div>
      <div className="flex flex-col lg:flex-row lg:items-center space-x-2">
        <div className="flex flex-col w-full">
          <p className="text-sm text-gray-70">Student</p>
          <input
            className="w-full bg-white border-gray-20 rounded-lg px-4 h-[34px] border"
            type="text"
            aria-label="Search..."
            placeholder="Search..."
            defaultValue={searchParams.get('student')}
            onChange={searchStudents}
          />
        </div>
        <div className="flex flex-col w-full max-w-44">
          <p className="text-sm text-gray-70">Cohort</p>
          <Select
            placeholder="Select..."
            isClearable={false}
            isSearchable={false}
            options={cohortsListOpts}
            defaultValue={cohortsListOpts.find(c => c.value === Number(searchParams.get('cohort_id')))}
            onChange={(e) => {
              filterStudentCohorts(e.value)
            }}
          />
        </div>
        <div className="flex flex-col w-full max-w-44">
          <p className="text-sm text-gray-70">Mid-Cycle Assessed</p>
          <Select
            placeholder="Select..."
            isClearable={false}
            isSearchable={false}
            options={statusListOpts}
            defaultValue={statusListOpts.find(c => c.value === searchParams.get('has_report_assessment'))}
            onChange={filterStatus('has_report_assessment')}
          />
        </div>
        <div className="flex flex-col w-full max-w-44">
          <p className="text-sm text-gray-70">Mid-Cycle Published</p>
          <Select
            placeholder="Select..."
            isClearable={false}
            isSearchable={false}
            options={statusListOpts}
            defaultValue={statusListOpts.find(c => c.value === searchParams.get('mid_cycle'))}
            onChange={filterStatus('mid_cycle')}
          />
        </div>
        <div className="flex flex-col w-full max-w-44">
          <p className="text-sm text-gray-70">Final Task Assessed</p>
          <Select
            placeholder="Select..."
            isClearable={false}
            isSearchable={false}
            options={statusListOpts}
            defaultValue={statusListOpts.find(c => c.value === searchParams.get('has_final_assessment'))}
            onChange={filterStatus('has_final_assessment')}
          />
        </div>
        <div className="flex flex-col w-full max-w-44">
          <p className="text-sm text-gray-70">End-Cycle Published</p>
          <Select
            placeholder="Select..."
            isClearable={false}
            isSearchable={false}
            options={statusListOpts}
            defaultValue={statusListOpts.find(c => c.value === searchParams.get('end_cycle'))}
            onChange={filterStatus('end_cycle')}
          />
        </div>
      </div>
      {studentsProgresses.length === 0 &&
        <div className="text-center text-gray-50 w-full p-5 border">No students found with report progress assessed for
          this cycle</div>
      }
      {studentsProgresses.length > 0 &&
        <table className="table-fixed w-full bg-white">
          <tbody>
            {studentsProgresses.map(item => (
              <StudentOptionsItem
                key={`options_${item.student_id}`}
                item={item}
                onCheckedStudentId={updateStudent}
                checked={studentIds.includes(item.student_id)}
              />
            ))}
          </tbody>
        </table>
      }
    </section>
  )
}

const StudentOptionsItem = ({ item, onCheckedStudentId, checked } = {}) => {
  const checkboxRef = useRef(null)
  const handleSelected = (e) => {
    if (e.target.nodeName === 'A') return
    if (e.target !== checkboxRef.current) checkboxRef.current.checked = !checkboxRef.current.checked
    onCheckedStudentId(item.student_id, checkboxRef.current.checked)
  }

  useEffect(() => {
    checkboxRef.current.checked = checked
  }, [checked])

  return (
    <tr className="border transition-all duration-200 ease-in-out hover:bg-gray-10" onClick={handleSelected}>
      <td className="py-2 w-16 text-center">
        <Checkbox ref={checkboxRef} id={`student_${item.student_id}`} name={`student_${item.student_id}`} onChange={handleSelected} className="rounded" />
      </td>
      <td className="w-2/3">{item.student_id} - {item.student_name}</td>
      <td className="w-36">
        <Pill type="mid_cycle" item={item} />
      </td>
      <td className="w-36">
        <Pill type="end_cycle" item={item} />
      </td>
      <td className="w-36 text-center">
        <Button
          as={SoraLink}
          to={`/students/${item.student_id}/reports`}
          target="_blank"
          size="xs"
          variant="outlined"
        >
          Preview
        </Button>
      </td>
    </tr>
  )
}
StudentOptionsItem.propTypes = {
  item: PropTypes.object,
  onCheckedStudentId: PropTypes.func,
  checked: PropTypes.bool,
}

const Pill = ({ type, item }) => {
  const showPill = item[type] || (type === 'mid_cycle' && item.has_report_assessment || type === 'end_cycle' && item.has_final_assessment)
  if (!showPill) return null

  const colourClassNames = item[type]
    ? { bg: 'bg-turquoise-50 border-turquoise-50', text: 'text-turquoise-50' }
    : { bg: 'bg-gray-60 border-gray-60', text: 'text-gray-60' }

  return (
    <div className={`flex items-center rounded-full border-2 w-32 p-px ${colourClassNames.bg}`}>
      <div className="flex border rounded-full h-5 w-5 m-px bg-white">
        <FontAwesomeIcon icon={faCheck} className={`m-auto ${colourClassNames.text}`} />
      </div>
      <p className="font-bold text-xs text-white mx-auto">{type.split('_').map(capitalize).join('-')}</p>
    </div>
  )
}
Pill.propTypes = {
  type: PropTypes.oneOf(['mid_cycle', 'end_cycle']),
  item: PropTypes.object,
}

export default ReleaseProgressReport
