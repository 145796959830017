import React, { useEffect } from 'react'
import { Link, Outlet, useLoaderData, useRevalidator, useSearchParams } from 'react-router'
import axios from 'axios'
import { Alert, Button, Icon, Typography, useFetcher, unstable_Tooltip as Tooltip, Combobox, Pill, cn } from '@design-system'
import serializeFormData from '@utils/serializeFormData'
import SoraLink from '@components/link'
import { PillStudentStatus, PillStudentStatusVariant } from '@blocks/pill-student-status'
import { isWithinInterval, differenceInMilliseconds } from 'date-fns'

type LoaderData = {
  startAt: string,
  endAt: string,
  conferenceUrlLabel: string
  conferenceUrlLink: string
  hasMatchingZoomEvent: boolean
  isWaitingZoomEvent: boolean
  isFailedToConnectWithZoom: boolean
  isEditingEnabled: boolean
  nextRefreshAt: string
  alert?: {
    variant?: 'notice'
    title: string
    description: string
    action: string
    label: string
  }
  attendances: {
    id: number
    studentId: number
    studentName: string
    studentStatus: PillStudentStatusVariant
    status: string
    notifiedAt: string
    options: string[]
  }[]
  externalStudentsAttendance: {
    id: number
    name: string
    email: string
    status: string
  }[]
}

async function loader({ request, params }) {
  const searchParams = new URL(request.url).searchParams
  const { data } = await axios.get(`/backoffice/facilitate/${params.type}/${params.experienceId}/attendance/default/${params.sessionId}?${searchParams.toString()}`)
  return data
}

async function action({ params, request }) {
  try {
    const formData = await request.formData()
    const serializedFormData = serializeFormData(formData)
    const response = await axios.post(`/backoffice/facilitate/${params.type}/${params.experienceId}/attendance/default/${params.sessionId}`, serializedFormData)
    return response.data
  } catch (error) {
    const data = error.response?.data
    return data || {
      toast: {
        message: 'Something went wrong. Please try again later.',
        appearance: 'error',
      }
    }
  }
}

const STATUS_BG_COLORS = {
  'Present': 'success-30',
  'Unexcused Absent': 'danger-5',
  'Insufficient': 'danger-5',
  'Excused Absent': undefined,
  'Late': 'yellow-20',
}

const STATUS_TOOLTIPS = {
  'Present': 'The student attended the meeting on time.',
  'Insufficient': "The student was present for less than half of the meeting's duration.",
  'Late': 'The student joined the meeting 3 or more minutes after it started.',
  'Unexcused Absent': "The student didn't attend the meeting and had no justified absence mathing the period.",
  'Excused Absent': "The student didn't attend the meeting but had a justified absence matching the period.",
}


function Element() {
  const [searchParams] = useSearchParams()
  const {
    startAt,
    endAt,
    conferenceUrlLabel,
    conferenceUrlLink,
    hasMatchingZoomEvent,
    isWaitingZoomEvent,
    isFailedToConnectWithZoom,
    isEditingEnabled,
    alert,
    attendances,
    externalStudentsAttendance,
    nextRefreshAt,
  } = useLoaderData() as LoaderData
  const revalidator = useRevalidator()
  const fetcher = useFetcher()
  const [isEditing, setIsEditing] = React.useState(false)

  const handleToggleEditingStatus = () => {
    if (isEditingEnabled) {
      setIsEditing(!isEditing)
    }
  }

  useEffect(() => {
    let timeoutId = null
    if (nextRefreshAt) {
      const waitFor = differenceInMilliseconds(new Date(nextRefreshAt), new Date(Date.now()))
      timeoutId = setTimeout(() => {
        revalidator.revalidate()
      }, waitFor)
    }
    return () => timeoutId && clearTimeout(timeoutId)
  }, [nextRefreshAt])

  useEffect(() => {
    if (fetcher.state === 'idle') {
      setIsEditing(false)
    }
  }, [fetcher.state])

  const isEventHappeningNow = isWithinInterval(new Date(), { start: new Date(startAt), end: new Date(endAt) })
  const editingTooltip = new Date(startAt) > new Date() ? "This event hasn't happened yet" : isEventHappeningNow ? "Event is happening now" : null

  return (
    <>
      <section className="flex flex-col gap-4">
        <div className="flex items-center gap-2">
          <Icon size='sm' name='clock' />
          <Typography>
            {new Date(startAt).toLocaleTimeString([])} to {new Date(endAt).toLocaleTimeString([], { timeZoneName: 'short' })}
          </Typography>
        </div>
        <div className="flex items-center gap-2">
          <Icon size='sm' name='link-2' />
          <a href={conferenceUrlLink} target='_blank' rel="noreferrer noopener"
            className="text-md hover:underline flex flex-row items-center gap-2 text-accent font-bold">
            {conferenceUrlLabel}
          </a>
        </div>
        <div className="flex items-center gap-2">
          <Icon size='sm' name='video-camera' className={isFailedToConnectWithZoom && 'text-danger'} />
          <Typography color={isFailedToConnectWithZoom ? 'danger' : undefined}>
            {hasMatchingZoomEvent
              ? (
                <>
                  Connected with Zoom Attendance. <Link
                    to={'zoom-report?' + searchParams.toString()}
                    className="hover:underline text-accent font-bold">
                    View Report
                  </Link>
                </>
              ) : isWaitingZoomEvent
                ? 'Waiting for meeting to start on Zoom'
                : 'Failed to connect with Zoom'
            }
          </Typography>
        </div>
      </section>
      {alert && (
        <Alert variant={alert.variant}>
          <Alert.Title>{alert.title}</Alert.Title>
          {alert.description && (
            <Alert.Description>{alert.description}</Alert.Description>
          )}
          {alert.action && (
            <Alert.Actions>
              <fetcher.Form method="POST">
                <input type="hidden" name="_action" value={alert.action} />
                <Button size='xs' type="submit">
                  {alert.label}
                </Button>
              </fetcher.Form>
            </Alert.Actions>
          )}
        </Alert>
      )}
      <section>
        <fetcher.Form method="POST" className="bg-white dark:bg-gray-100 border dark:border-gray-90 rounded">
          <table className="table-fixed w-full text-center">
            <thead>
              <tr>
                <th className="p-3 text-left border-b dark:border-gray-90" colSpan={1}>
                  <Typography variant='heading-6' weight='bold'>
                    Attendance
                  </Typography>
                </th>
                <th className="p-3 text-left border-b dark:border-gray-90 m-auto" colSpan={2}>
                  {nextRefreshAt ? <div>
                    <Typography variant='footnote' weight='bold' className="animate-pulse">
                      Refreshing...
                    </Typography>
                  </div> : null}
                </th>
                <th className="p-3 text-right border-b dark:border-gray-90" colSpan={1}>
                  {!isEditing ? (
                    editingTooltip ? (
                      <Tooltip content={editingTooltip}>
                        <Button type="button" size='sm' variant='outlined' disabled={!isEditingEnabled} onClick={handleToggleEditingStatus}>
                          Edit
                        </Button>
                      </Tooltip>
                    ) : (
                      <Button type="button" size='sm' variant='outlined' disabled={!isEditingEnabled} onClick={handleToggleEditingStatus}>
                        Edit
                      </Button>
                    )
                  ) : (
                    <div className="flex flex-row gap-2 justify-end">
                      <Button type="button" size='sm' variant='outlined' onClick={handleToggleEditingStatus}>
                        Cancel
                      </Button>
                      <Button type="submit" size='sm' name="_action" value="overwrite_attendances">
                        Save
                      </Button>
                    </div>
                  )}
                </th>
              </tr>
              <tr>
                <th className="p-3 border-b dark:border-gray-90 text-left">
                  <Typography variant="footnote" weight="bold">Student name</Typography>
                </th>
                <th className="p-3 border-b dark:border-gray-90 text-left">
                  <Typography variant="footnote" weight="bold">Status</Typography>
                </th>
              </tr>
            </thead>
            <tbody>
              {attendances.map(attendance => (
                <tr key={attendance.id} className="hover:bg-gray-5 dark:hover:bg-gray-98">
                  <td>
                    {isEditing && (
                      <input type="hidden" name={`attendances[${attendance.id}][id]`} defaultValue={attendance.id} />
                    )}
                    <div className={cn('p-3 text-left flex items-center gap-2', attendance.studentStatus !== 'active' && attendance.studentStatus !== 'admitted' && 'opacity-50')}>
                      <div>
                        <SoraLink to={`/students/${attendance.studentId}/overview`} className='hover:underline'>
                          {attendance.studentName}
                        </SoraLink>
                        {attendance.notifiedAt && (
                          <div className="text-xxs text-danger-60 -bottom-3">
                            Parents notified in {new Date(attendance.notifiedAt).toLocaleString([], {
                              month: 'short',
                              day: 'numeric',
                              hour: 'numeric',
                              minute: 'numeric',
                            })}
                          </div>
                        )}
                      </div>
                      {attendance.studentStatus !== 'active' && <PillStudentStatus variant={attendance.studentStatus} />}
                    </div>
                  </td>
                  <td>
                    <div className="p-3 flex flex-row gap-2">
                      {isEditing ? (
                        <Combobox
                          name={`attendances[${attendance.id}][status]`}
                          defaultValue={attendance.status}>
                          {attendance.options.map(option => (
                            <Combobox.Item key={option} value={option}>{option}</Combobox.Item>
                          ))}
                        </Combobox>
                      ) : attendance.status.split(',').map(st => {
                        return <Tooltip content={STATUS_TOOLTIPS[st.trim()]} key={st}>
                          <Pill color={STATUS_BG_COLORS[st.trim()]}>
                            <Pill.Value>{st}</Pill.Value>
                          </Pill>
                        </Tooltip>
                      })}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </fetcher.Form>
      </section>
      {externalStudentsAttendance.length > 0 && (
        <section>
          <table className="table-fixed w-full text-center bg-white dark:bg-gray-100 border dark:border-gray-90 rounded">
            <thead>
              <tr>
                <th className="p-3 text-left border-b dark:border-gray-90" colSpan={2}>
                  <Typography variant='heading-6' weight='bold'>
                    External Students Attendance
                  </Typography>
                </th>
              </tr>
              <tr>
                <th className="p-3 border-b dark:border-gray-90 text-left">
                  <Typography variant="footnote" weight="bold">External Student Name</Typography>
                </th>
                <th className="p-3 border-b dark:border-gray-90 text-left">
                  <Typography variant="footnote" weight="bold">Status</Typography>
                </th>
              </tr>
            </thead>
            <tbody>
              {externalStudentsAttendance.map((attendance) => (
                <tr key={attendance.id} className="hover:bg-gray-5 dark:hover:bg-gray-98">
                  <td>
                    <div className="p-3 text-left">
                      {attendance.name}
                    </div>
                  </td>
                  <td>
                    <div className="p-3 flex flex-row gap-2">
                      <Pill key={`${attendance.status}-${attendance.id}`} color={STATUS_BG_COLORS[attendance.status]}>
                        <Pill.Value>{attendance.status}</Pill.Value>
                      </Pill>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </section>
      )}
      <Outlet />
    </>
  )
}

export const FacilitateExperienceAttendanceDefaultSessionRoute = {
  loader,
  action,
  Element,
}
