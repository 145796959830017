import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { TemplateSelector } from './template-selector'
import { Button } from '@design-system'

export const TemplateManagement = ({ cycleId, templates = [], activeTemplates = [], onChange }) => {
  const [templateIds, setTemplateIds] = useState(templates.map(template => template.id))
  const [selectedTemplates, setSelectedTemplates] = useState(templates.map(template => template.id))

  useEffect(() => {
    onChange?.(templateIds)
  }, [templateIds, onChange])

  const handleRemoveTemplate = (templateId) => {
    setTemplateIds(templateIds.filter(id => id !== templateId))
    setSelectedTemplates(selectedTemplates.filter(id => id !== templateId))
  }

  const handleUpdateTemplate = (oldId, newId) => {
    setTemplateIds(templateIds.map(id => id === oldId ? newId : id))
    setSelectedTemplates(selectedTemplates.map(id => id === oldId ? newId : id))
  }

  const handleTemplateSelect = (templateId) => {
    setSelectedTemplates([...selectedTemplates, templateId])
  }

  const hasUnselectedTemplate = templateIds.some(id => !selectedTemplates.includes(id))

  return (
    <>
      {templateIds.map((tmpId, tmpIdx) => (
        <TemplateSelector 
          key={`template_${tmpIdx}${tmpId}`} 
          index={tmpIdx} 
          cycleId={cycleId} 
          templateId={tmpId} 
          groupList={activeTemplates} 
          onRemoveTemplate={handleRemoveTemplate} 
          onUpdateTemplate={handleUpdateTemplate}
          onSelect={handleTemplateSelect}
        />
      ))}
      <Button 
        variant='secondary' 
        size='lg' 
        className='mt-2' 
        disabled={hasUnselectedTemplate}
        onClick={(e) => { 
          e.preventDefault()
          setTemplateIds([...templateIds, null])
        }}
      >
        Add Template
      </Button>
    </>
  )
}

TemplateManagement.propTypes = {
  cycleId: PropTypes.number,
  templates: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    units: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      title: PropTypes.string,
    })),
  })),
  activeTemplates: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    items: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      title: PropTypes.string,
    })),
  })),
  onChange: PropTypes.func,
}