import React from 'react'
import axios from 'axios'
import serializeFormData from '@utils/serializeFormData'
import { useLoaderData, useSearchParams } from 'react-router'
import { SchoolYearSelector } from './school-year-selector'
import { ColumnFilter } from './column-filter'
import { Avatar, Card, Typography } from '@design-system'
import Pathways from './pathways'

interface SchoolYear {
  year: number
  title: string
  selected: boolean
  cycleIds: number[]
}

interface House {
  value: string | number
  label: string
}

interface Pathway {
  id: number
  title: string
}

interface PathwayAssigned {
  id: number
  title: string
  pathway_id: number
}

interface Student {
  id: number
  name: string
  grade: number
  profile_image_url?: string
  house_title: string
  house_advisor: string
  stem_pathways?: PathwayAssigned[]
  humanities_pathways?: PathwayAssigned[]
}

interface LoaderData {
  availableSchoolYears: SchoolYear[]
  houses: House[]
  students: Student[]
  humanitiesPathways: Pathway[]
  stemPathways: Pathway[]
}

interface ActionData {
  toast?: {
    message: string
    appearance: 'error' | 'success'
  }
}

const getOrdinalGrade = (grade: number): string => {
  const suffix = ['th', 'st', 'nd', 'rd']
  const v = grade % 100
  return `${grade}${suffix[(v - 20) % 10] || suffix[v] || suffix[0]}`
}

const loader = async ({ params, request }: { params: any; request: Request }): Promise<LoaderData> => {
  const searchParams = new URL(request.url).searchParams
  const action = searchParams.get('_action') || 'default'
  const schoolYear = searchParams.get('schoolYear')
  const houseId = searchParams.get('houseId')

  const result = await axios.get(`/backoffice/workbench/pathways/students`, { params: { action, schoolYear, houseId } })
  return result?.data
}

const action = async ({ params, request }: { params: any; request: Request }): Promise<ActionData> => {
  const formData = await request.formData()
  const action = formData.get('_action')
  const serializedFormData = serializeFormData(formData)

  try {
    const result = await axios.post(`/backoffice/workbench/pathways/students`, { ...serializedFormData, action })
    return result?.data
  } catch (error) {
    return {
      toast: { message: 'There was an error running this action. If the problem persists, contact our tech support team.', appearance: 'error' }
    }
  }
}

const Element: React.FC = () => {
  const [searchParams] = useSearchParams()
  const { availableSchoolYears, houses, students, humanitiesPathways, stemPathways } = useLoaderData<LoaderData>()

  const schoolYears = availableSchoolYears.map(year => ({
    value: year.year,
    label: year.title,
    selected: searchParams.get('schoolYear') ? Number(searchParams.get('schoolYear')) === year.year : year.selected,
  }))

  const selectedSchoolYear = Number(searchParams.get('schoolYear')) || schoolYears.slice(-1)[0].value

  return (
    <div className='flex flex-col p-2 gap-2'>
      <SchoolYearSelector schoolYears={schoolYears} defaultValue={schoolYears.find(year => year.selected)?.value} />
      <Card>
        <Card.Content className='flex flex-row justify-around p-0 relative'>
          <table className='w-full'>
            <thead className='bg-screen-primary z-20'>
              <tr className='text-left'>
                <th className='px-4 py-2'>Student</th>
                <th className='px-4 py-2'>
                  <ColumnFilter field='houseId' title='House' options={houses}/>
                </th>
                <th className='px-4 p w-[250px]'>Humanities Pathways</th>
                <th className='px-4 p w-[250px]'>Stem Pathways</th>
              </tr>
            </thead>
            <tbody>
              {students?.map(student => (
                <tr key={`${selectedSchoolYear}_${student.id}`} className='border-t'>
                  <td className='px-4 py-2'>
                    <div className='flex flex-row gap-2 items-center'>
                      <Avatar size='lg'>
                        <Avatar.Image src={student.profile_image_url} />
                        <Avatar.Fallback>{student.name.split(' ').map(name => name[0]).join(' ')}</Avatar.Fallback>
                      </Avatar>
                      <div className='flex flex-col'>
                        <Typography variant='callout' weight='bold'>{student.name}</Typography>
                        <Typography variant='callout'>{getOrdinalGrade(student.grade)} grade</Typography>
                      </div>
                    </div>
                  </td>
                  <td className='px-4 py-2'>
                    <Typography variant='callout' weight='bold'>{student.house_title}</Typography>
                    <Typography variant='callout'>{student.house_advisor}</Typography>
                  </td>
                  <td className='px-4 py-2 w-1/3 group/pathways'>
                    <Pathways type='humanities' availablePathways={humanitiesPathways} assignedPathways={student.humanities_pathways} studentId={student.id} schoolYear={selectedSchoolYear} />
                  </td>
                  <td className='px-4 py-2 w-1/3 group/pathways'>
                    <Pathways type='stem' availablePathways={stemPathways} assignedPathways={student.stem_pathways} studentId={student.id} schoolYear={selectedSchoolYear} />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </Card.Content>
      </Card>
    </div>
  )
}

export const WorkbenchPathwaysStudentsRoute = {
  loader,
  action,
  Element,
}
