import React from 'react'
import { Button, Combobox, Icon, Typography, useFetcher } from '@design-system'

interface Pathway {
  id: number
  title: string
}

interface AssignedPathway {
  id: number
  title: string
  pathway_id: number
}

interface PathwayProps {
  availablePathways?: Pathway[]
  assignedPathways?: AssignedPathway[]
  studentId: number
  schoolYear: number
  type: string
}

const Pathways = ({ availablePathways, assignedPathways, studentId, schoolYear, type }: PathwayProps) => {
  const fetcher = useFetcher()

  const isLoading = fetcher.state !== 'idle' && fetcher.formData?.get('studentId') === String(studentId) && fetcher.formData?.get('type') === type && fetcher.formData?.get('schoolYear') === String(schoolYear)

  const handleSelectAll = () => {
    const formData = new FormData()
    formData.append('_action', 'save-student-pathway')
    formData.append('schoolYear', schoolYear.toString())
    formData.append('studentId', studentId.toString())
    formData.append('type', type)
    availablePathways?.forEach(pathway => {
      formData.append('pathwayIds[]', String(pathway.id))
    })
    fetcher.submit(formData, { method: 'post' })
  }

  const handleResetToDefault = () => {
    const formData = new FormData()
    formData.append('_action', 'save-student-pathway')
    formData.append('schoolYear', schoolYear.toString())
    formData.append('studentId', studentId.toString())
    formData.append('type', type)
    
    fetcher.submit(formData, { method: 'post' })
  }

  return (
    <div className='flex flex-row gap-2 items-center relative'>
      {assignedPathways?.length > 0 ? (
        <ul className='flex flex-col gap-2'>
          {assignedPathways?.map(pathway => (
            <li key={pathway.id}>
              <Typography variant='callout'>{pathway.title}</Typography>
            </li>
          ))}
        </ul>
      ) : (
        <Typography variant='callout'>-</Typography>
      )}
      <fetcher.Form method='post' onChange={(e) => {
        fetcher.submit((e.target as HTMLFormElement).form)
      }}>
        <input type="hidden" name="_action" value="save-student-pathway" />
        <input type="hidden" name="studentId" value={studentId} />
        <input type="hidden" name="type" value={type} />
        <input type="hidden" name="schoolYear" value={schoolYear} />
        <Combobox 
          key={`${studentId}-${type}-${schoolYear}-${assignedPathways?.map(ap => ap.pathway_id).join('-')}`}
          name="pathwayIds[]" 
          variant="freeform"
          multiple={true}
          popoverContentProps={{ side: 'top', align: 'center' }}
          customTrigger={
            <Button variant='ghost' size='xs' className='opacity-0 group-hover/pathways:opacity-100'>
              <Icon name='edit-3' size='xs' />
            </Button>
          }
          defaultValue={assignedPathways?.map(ap => String(ap.pathway_id))}
          loading={isLoading}
        >
          {availablePathways?.map(pathway => (
            <Combobox.Item key={pathway.id} value={String(pathway.id)}>
              {pathway.title}
            </Combobox.Item>
          ))}
          <Combobox.Separator />
          <Combobox.ItemAction onSelect={handleSelectAll}>
            Select All
          </Combobox.ItemAction>
          <Combobox.ItemAction onSelect={handleResetToDefault}>
            Reset to default
          </Combobox.ItemAction>
        </Combobox>
      </fetcher.Form>
    </div>
  )
}

export default Pathways
