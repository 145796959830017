import React, { useState } from 'react'
import { Button, Combobox, Icon, Typography } from '@design-system'
import { UnitList } from './unit-list'
import PropTypes from 'prop-types'

export const TemplateSelector = ({ index, cycleId, templateId, groupList = [], onRemoveTemplate, onUpdateTemplate, onSelect }) => {
  const initialUnits = groupList.reduce((acc, curr) => { 
    return [
      ...(acc || []), 
      ...(curr.items.find(item => item.id === templateId)?.units || [])] 
  } , [])
  const [units, setUnits] = useState(initialUnits)
  
  const handleChange = (event) => {
    const id = Number(event.target.value)
    const units = groupList.reduce((acc, curr) => { 
      return [...(acc || []), ...(curr.items.find(item => item.id === id)?.units || [])] 
    } , [])
    setUnits(units)
    onUpdateTemplate(templateId, id)
    onSelect(id)
  }

  return (
    <React.Fragment>
      <div className="flex flex-row items-center pt-4 border-t border-alpha/10">
        <Typography variant="body" weight="bold" className='w-1/4'>Template {index+1}</Typography>
        <div className='flex flex-row flex-1 gap-3 items-center'>
          <Combobox 
            name='templateIds[]' 
            className='flex-1' 
            placeholder='Select one template' 
            defaultValue={templateId} 
            search={<Combobox.Search placeholder="Search a template" />} 
            onChange={handleChange}
            required
          >
            {groupList.map((group) => (
              <Combobox.Group key={`${cycleId}_${group.label}`} heading={group.label}>
                {group.items.map(item => (
                  <Combobox.Item key={item.id} value={item.id}>{item.title}</Combobox.Item>
                ))}
              </Combobox.Group>
            ))}
          </Combobox>
          <Button variant='ghost' size='sm' onClick={() => onRemoveTemplate(templateId)}>
            <Icon name='trash' />
          </Button>
        </div>
      </div>
      <div className="flex flex-row items-center pt-4 border-t border-alpha/10">
        <Typography variant="body" weight="bold" className='w-1/4'>Template {index+1} Units</Typography>
        <UnitList units={units} disabled={true} />
      </div>
    </React.Fragment>
  )
}
TemplateSelector.propTypes = {
  index: PropTypes.number,
  cycleId: PropTypes.number,
  templateId: PropTypes.number,
  groupList: PropTypes.array,
  onRemoveTemplate: PropTypes.func,
  onUpdateTemplate: PropTypes.func,
  onSelect: PropTypes.func,
}